import React from 'react'
import styled from 'styled-components'
import { height, width, HeightProps, WidthProps } from 'styled-system'
import Box from '../atoms/Box'
import Flex from '../atoms/Flex'
import Heading from '../atoms/Heading'
import { large, small, xsmall, xxsmall, AvatarSizeProps } from './AvatarSize'
interface AvatarBaseProps extends HeightProps, WidthProps, AvatarSizeProps {
  backgroundColor?: string
}
interface TextAvatarProps extends AvatarBaseProps {
  text: string
  context?: 'Parent' | 'Educator'
}
const AvatarBase = styled.div<AvatarBaseProps>`
  ${height};
  ${width};
  ${props => large(props)};
  ${props => small(props)};
  ${props => xsmall(props)};
  ${props => xxsmall(props)};
  background: ${props => props.theme.colors[props.backgroundColor || 'transparent']};
  vertical-align: middle;
`
AvatarBase.defaultProps = {
  height: '80px',
  width: '80px',
}
const TextAvatar = (props: TextAvatarProps) => {
  const { large, small, xsmall, xxsmall, text, context } = props
  let textColor = 'avatarText'
  let backgroundColor = 'avatarBackground'
  let AvatarHeading
  switch (large || small || xsmall || xxsmall) {
    case large:
      AvatarHeading = (
        <Heading.h2 bold={false} my={0} upper color={textColor}>
          {text}
        </Heading.h2>
      )
      break
    case small:
      AvatarHeading = (
        <Heading.h5 my={0} upper color={textColor}>
          {text}
        </Heading.h5>
      )
      break
    case xsmall:
      AvatarHeading = (
        <Heading.h6 my={0} upper color={textColor}>
          {text}
        </Heading.h6>
      )
      break
    case xxsmall:
      AvatarHeading = (
        <Heading.h6 fontSize={0} my={0} upper color={textColor}>
          {text}
        </Heading.h6>
      )
      break
    default:
      AvatarHeading = (
        <Heading.h4 my={0} upper color={textColor}>
          {text}
        </Heading.h4>
      )
      break
  }

  if (context === 'Parent') {
    textColor = 'parentDark'
    backgroundColor = 'parentLight'
  }
  if (context === 'Educator') {
    textColor = 'educatorDark'
    backgroundColor = 'educatorLight'
  }
  return (
    <AvatarBase
      large={large}
      small={small}
      xsmall={xsmall}
      xxsmall={xxsmall}
      backgroundColor={backgroundColor}>
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Box>{AvatarHeading}</Box>
      </Flex>
    </AvatarBase>
  )
}
TextAvatar.displayName = 'TextAvatar'
export default TextAvatar

import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Alert, Button, Stack, Text, TextField } from '@myxplor/stardust'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import PasswordStrengthMeter from '~/components/PasswordStrengthMeter'

const minPasswordLength = 8

interface Props extends RouteComponentProps {
  errorMessage?: Nullable<string>
  onClear(): void
  onNext(password: string): void
  onBack(): void
}

const SetPassword = ({ errorMessage, onClear, onNext, onBack }: Props) => {
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  useEffect(() => {
    onClear()
  }, [])

  const validateLength = () => password.length >= minPasswordLength

  const validateMatch = () => password.length > 0 && password === repeatPassword

  const validatePassword = () => validateMatch() && validateLength()

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Create Password
      </Header>
      <Content>
        <Stack space="large">
          <Stack space="small">
            <TextField
              label="Password"
              name="xplor_password"
              type="password"
              minLength={minPasswordLength}
              value={password}
              onChange={(value: string) => setPassword(value)}
            />
            <TextField
              label="Repeat Password"
              name="repeat_xplor_password"
              type="password"
              minLength={minPasswordLength}
              value={repeatPassword}
              onChange={(value: string) => setRepeatPassword(value)}
            />
            <PasswordStrengthMeter password={password} />
            <Text decorate={validateLength() ? 'thru' : 'none'} prefab="caption">
              {minPasswordLength} characters
            </Text>
            <Text decorate={validateMatch() ? 'thru' : 'none'} prefab="caption">
              passwords don&apos;t match
            </Text>
            {errorMessage && <Alert tone="negative">{errorMessage}</Alert>}
          </Stack>
        </Stack>
      </Content>
      <Footer>
        <Button fill size="large" disabled={!validatePassword()} onClick={() => onNext(password)}>
          Next
        </Button>
      </Footer>
    </Container>
  )
}

export default SetPassword

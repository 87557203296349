import React from 'react'

const Envelope = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="rgba(0, 0, 0, 0.54)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".87"
      strokeWidth="1.5"
      d="M.75 6c0-.82843.67157-1.5 1.5-1.5h19.5c.8284 0 1.5.67157 1.5 1.5v12c0 .8284-.6716 1.5-1.5 1.5H2.25c-.82843 0-1.5-.6716-1.5-1.5V6z"
      clipRule="evenodd"
    />
    <path
      stroke="rgba(0, 0, 0, 0.54)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".87"
      strokeWidth="1.5"
      d="M4.5 13.5h3.75M4.5 16.5h8.25"
    />
    <path
      stroke="rgba(0, 0, 0, 0.54)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".87"
      strokeWidth="1.5"
      d="M15.75 8.25c0-.41421.3358-.75.75-.75h3c.4142 0 .75.33579.75.75v3c0 .4142-.3358.75-.75.75h-3c-.4142 0-.75-.3358-.75-.75v-3z"
      clipRule="evenodd"
    />
  </svg>
)

export default React.memo(Envelope)

import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import useFetch from 'use-http'

import { Alert, Box, Card, Center, Heading, Loading, Stack, Text, TextField, Button } from '@myxplor/stardust'

import { isEmailValid } from '~/utils'

import { getErrorMessage } from '~/api'

type Props = RouteComponentProps

const MergeXplorIds = (_props: Props) => {
  const { request, loading, response } = useFetch()
  const [sourceEmail, setSourceEmail] = useState('')
  const [isSourceEmailValid, setIsSourceEmailValid] = useState(true)
  const [destinationEmail, setDestinationEmail] = useState('')
  const [isDestinationEmailValid, setIsDestinationEmailValid] = useState(true)
  const [alertMessage, setAlertMessage] = useState<Nullable<string>>(null)
  const [negativeTone, setNegativeTone] = useState(true)

  const showAlert = (message: string, isNegative: boolean) => {
    setAlertMessage(message)
    setNegativeTone(isNegative)
  }

  const showPositiveAlert = (message: string) => showAlert(message, false)

  const showNegativeAlert = (message: string) => showAlert(message, true)

  const onConfirmMergeRequest = async () => {
    const message = `Are you sure you want to merge Xplor ID '${sourceEmail}' to '${destinationEmail}'? This action cannot be undone.`
    if (confirm(message)) {
      await request.post('/api/v1/admin/merge-xplor-ids/send-email', {
        source_email: sourceEmail,
        destination_email: destinationEmail,
      })

      if (response.ok) {
        showPositiveAlert(`Merge Xplor IDs request email has been sent to ${sourceEmail}`)
      } else {
        showNegativeAlert(getErrorMessage(response) || '')
      }
    }
  }

  const onSubmitMergeXplorIDsRequest = () => {
    const isSourceEmailValid = isEmailValid(sourceEmail)
    const isDestinationEmailValid = isEmailValid(destinationEmail)
    setIsSourceEmailValid(isSourceEmailValid)
    setIsDestinationEmailValid(isDestinationEmailValid)

    if (isSourceEmailValid && isDestinationEmailValid) {
      onConfirmMergeRequest()
    }
  }

  return (
    <Center y={false}>
      <Box maxWidth={900} width={1}>
        <Card>
          <Box padding="large">
            <Stack space="small">
              <Heading level={4}>Merge Xplor IDs</Heading>

              <Box paddingTop="xsmall">
                <Text color="highestEmphasis">Enter a source XplorID email:</Text>
              </Box>

              <Box paddingTop="xxsmall" paddingBottom="xsmall" width={1}>
                <TextField
                  autoComplete="off"
                  label="Source Email"
                  name="search"
                  onChange={(value: string) => setSourceEmail(value)}
                />
                <Box paddingTop="xxsmall" height="20px">
                  {!isSourceEmailValid && (
                    <Text color="negative" size="caption">
                      Invalid source email format
                    </Text>
                  )}
                </Box>
              </Box>

              <Text color="highestEmphasis">Enter a destination XplorID email:</Text>

              <Box paddingTop="xxxsmall" width={1}>
                <TextField
                  autoComplete="off"
                  label="Destination Email"
                  name="search"
                  onChange={(value: string) => setDestinationEmail(value)}
                />
                <Box paddingTop="xxsmall" height="20px">
                  {!isDestinationEmailValid && (
                    <Text color="negative" size="caption">
                      Invalid destination email format
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent="center" width={1} paddingTop="medium">
                <Box width="170px">
                  <Button color="orange900" onClick={() => onSubmitMergeXplorIDsRequest()} fill size="large">
                    {'Merge Xplor IDs'}
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Card>

        {loading && <Loading size="large" />}

        {!loading && alertMessage && (
          <Box paddingTop="medium">
            <Alert tone={negativeTone ? 'negative' : 'positive'}>{alertMessage}</Alert>
          </Box>
        )}
      </Box>
    </Center>
  )
}

export default MergeXplorIds

import React, { useEffect } from 'react'
import useFetch from 'use-http'

import { Alert, Box, Card, Center, Heading, Stack, Text } from '@myxplor/stardust'

const Impersonation = (props: any) => {
  return (
    <Center y={false}>
      <Box maxWidth={900} width={1}>
        <Stack space="small">
          <Card>
            <Box padding="large">
              <Stack space="small">
                <Heading level={4}>Impersonation</Heading>
                <Text color="highestEmphasis">{props.message}</Text>
              </Stack>
            </Box>
          </Card>
        </Stack>
      </Box>
    </Center>
  )
}

const GetPermissions = (_props: any) => {
  const { request, data, error, loading } = useFetch()

  useEffect(() => {
    request.get('/api/v1/support-user/')
  }, [])

  if (loading) return <>Loading</>
  if (error) return <Alert tone="negative">{error}</Alert>
  if (data) return <Impersonation message={data.data.message} permissions={data.data.permissions} />
  return <></>
}

Impersonation.displayName = 'Impersonation'
export default GetPermissions

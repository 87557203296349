import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Animation, Box, Stack, Text, TextField } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'
import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'

type Props = RouteComponentProps

const Success = (_props: Props) => {
  const [sessionDetails] = useContext(SessionContext)

  const onBack = () => {
    history.replaceState(null, '', '/')
    navigate('/log-in')
  }

  return (
    <Container>
      <Header icon={'close'} onBack={onBack}>
        Welcome to Playground
      </Header>
      <Content>
        <Stack align="center" space="large">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            You are now ready to use the Playground App. To login to Playground on your tablet devices, please
            use the username below.
          </Text>
          <TextField
            label="Username"
            name="username"
            value={sessionDetails.identifier || ''}
            readOnly
            disabled
          />
          <Text color="higherEmphasis" prefab="body" align="center">
            Download the Playground App to access great features for educators.
          </Text>
        </Stack>
      </Content>
    </Container>
  )
}

export default Success

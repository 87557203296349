import React, { useContext, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import { redirect } from '~/utils'

type Props = RouteComponentProps

const StatusCheck = (_props: Props) => {
  const { token: authToken } = useContext(AuthContext)
  const [sessionDetails] = useContext(SessionContext)

  const { request, response } = useFetch({ credentials: 'include' })

  const checkStatus = async () => {
    await request.get('/api/v1/upgrades/manual/status')

    if (response.ok && response.data.data.upgrade_status === 'pending') {
      navigate('/manual-upgrade/prompt')
    } else {
      redirect(sessionDetails, authToken)
    }
  }

  useEffect(() => {
    checkStatus()
  }, [])

  return <></>
}

export default StatusCheck

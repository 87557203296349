import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Alert, Box, Form, Button, Stack, Text, TextField } from '@myxplor/stardust'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'
import MobileIcon from '~/icons/Mobile'

import { filterNonValidPhoneChar, isPhoneValid } from '~/utils'

interface Props extends RouteComponentProps {
  errorMessage?: Nullable<string>
  onClear?(): void
  onBack(): void
  onNext(phone: string): void
}

const SetPhone = ({ errorMessage, onClear, onBack, onNext }: Props) => {
  const [phone, setPhone] = useState('')
  const [displayError, setDisplayError] = useState(false)

  const validatePhone = () => isPhoneValid(phone)

  useEffect(() => {
    onClear && onClear()
  }, [])

  const onSubmit = async () => {
    setDisplayError(true)
    onNext(phone.replace(/\+/g, ''))
  }

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        <Header icon="back" onBack={onBack}>
          Enter mobile number
        </Header>
        <Content>
          <Stack space="large">
            <Text color="higherEmphasis" prefab="body">
              This mobile number will be used to log into Xplor products.
            </Text>
            <Stack space="small">
              <TextField
                label="Mobile"
                name="xplor_phone"
                value={phone}
                minLength={6}
                trailingIcon={
                  <Box color="primary" height={24} width={24}>
                    <MobileIcon />
                  </Box>
                }
                onChange={(value: string) => {
                  setDisplayError(false)
                  setPhone(filterNonValidPhoneChar(value))
                }}
              />
              <Text decorate={validatePhone() ? 'thru' : 'none'} prefab="caption">
                invalid phone number
              </Text>
              {displayError && errorMessage && <Alert tone="negative">{errorMessage}</Alert>}
            </Stack>
          </Stack>
        </Content>
        <Footer>
          <Button type="submit" disabled={!validatePhone()} fill size="large">
            Next
          </Button>
        </Footer>
      </Container>
    </Form>
  )
}

export default SetPhone

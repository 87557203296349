import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import CreateAccountForm from '~/pages/CreateAccountForm'
import ResendEmail from '~/pages/ResendEmail'
import AutoLink from './AutoLink'
import Welcome from './Welcome'
import ExistingAccount from './ExistingAccount'

type Props = RouteComponentProps

const Onboarding = (_props: Props) => {
  const finaliseEndpoint = '/api/v1/onboarding/finalise'
  const resendEndpoint = '/api/v1/onboarding/send-email'

  return (
    <CenteredContentLayout>
      <Router>
        <AutoLink path="/" />
        <ExistingAccount path="existing-account" />
        <Welcome path="welcome" />
        <CreateAccountForm finaliseEndpoint={finaliseEndpoint} path="create-account/*" />
        <ResendEmail resendEndpoint={resendEndpoint} path="resend-email" />
      </Router>
    </CenteredContentLayout>
  )
}

export default Onboarding

import React from 'react'
import { Router, Redirect } from '@reach/router'

import AccountRecovery from '~/pages/AccountRecovery'
import Login from '~/pages/Login'
import Migration from './Migration'
import MagicLink from './MagicLink'
import MergeXplorIds from './MergeXplorIds'
import ProductSelector from './ProductSelector'
import AutoUpgrade from './AutoUpgrade'
import Onboarding from './Onboarding'
import ServiceLoginOnboarding from './ServiceLoginOnboarding'
import ManualUpgrade from './ManualUpgrade'
import Error from './Error'
import HubGuestLanding from './HubGuestLanding'
import DeprecatedFeature from './DeprecatedFeature'
import SsoLogin from './SsoLogin'
import ProfileSelector from './ProfileSelector'

/*
Dear future Adam,

This is past Adam.

Just a friendly reminder, if you want to redirect to a route in
this router from a route in the RouterUnauthenticated, you MUST include the source 
route here. 

There appears to be an issue that is causing the match to fail when redirecting
from a sibling router, and you will hit the default redirect and end up back
at the Login page rather than in the route you are trying to redirect to.

Not sure if this an issue with reach router or the way we are using it though.

TL;DR duplicate the unathenticated routes here

Farewell, and I will see you in hell.

*/

// Routes for Authenticated users
const RouterAuthenticated = () => {
  return (
    <Router>
      <Login path="/" />
      <Login path="login/" />
      <SsoLogin path="sso/" />
      <ProfileSelector path="profile-selection/*" />
      <Migration path="migration/*" />
      <AccountRecovery path="account-recovery/*" />
      <MagicLink path="magic-link/*" />
      <MergeXplorIds path="merge-xplor-ids/*" />
      <ProductSelector path="product-selector" />
      <AutoUpgrade path="auto-upgrade/*" />
      <HubGuestLanding path="hubguest" />
      <Onboarding path="onboarding/*" />
      <ServiceLoginOnboarding path="service-login-onboarding/*" />
      <ManualUpgrade path="manual-upgrade/*" />
      <DeprecatedFeature path="/link-accounts" />
      <Error path="error" />
      <Redirect from="*" to="/" default noThrow />
    </Router>
  )
}

export default RouterAuthenticated

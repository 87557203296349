import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Box, Stack, Text } from '@myxplor/stardust'

import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'

import EmailIcon from '~/icons/Email'

type Props = RouteComponentProps

const ForgotEmail = (_props: Props) => {
  const onBack = () => history.back()
  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Forgot Email
      </Header>

      <Content>
        <Stack align="center" space="large">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            Please contact your child care centre administrator to find your accounts email address.
          </Text>
        </Stack>
      </Content>

      <Footer />
    </Container>
  )
}

export default ForgotEmail

import React, { useState, useContext } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import useFetch from 'use-http'

import { SessionContext } from '~/contexts/SessionContext'
import { getErrorMessage } from '~/api'
import SetPassword from '~/components/SetPassword'

const SetPasswordForm = (_props: RouteComponentProps) => {
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
  const { request, response } = useFetch()

  const onBack = () => history.back()

  const onClear = () => {
    setErrorMessage(null)
  }

  const onNext = async (password: string) => {
    setErrorMessage(null)

    await request.post('/api/v1/service-login-onboarding/finalise', { password })

    if (response.ok) {
      const data = response.data.data

      setSessionDetails({ ...sessionDetails, identifier: data.identifier })

      navigate('/service-login-onboarding/success')
    } else {
      setErrorMessage(getErrorMessage(response))
    }
  }

  return <SetPassword errorMessage={errorMessage} onClear={onClear} onBack={onBack} onNext={onNext} />
}

export default SetPasswordForm

import config from '~/config'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

import { globalHistory } from '@reach/router'

globalHistory.listen(({ location }) => {
  ReactGA.set({ page: location.pathname })
})

export const initialise = () => {
  ReactGA.initialize([
    {
      trackingId: config.GOOGLE_ANALYTICS_PROPERTY || '',
      gaOptions: {
        sampleRate: 100,
        siteSpeedSampleRate: 5,
      },
    },
  ])
}

const tagManagerArgs = {
  gtmId: config.GOOGLE_TAG_MANAGER_ID || '',
}

export async function initializeGoogleTagManager() {
  TagManager.initialize(tagManagerArgs)
}

import React, { ReactNode, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Box, Card, Column, Columns, Image, Stack, Text, useTheme } from '@myxplor/stardust'
import homeLogo from '~/assets/images/home_16px.png'
import playgroundLogo from '~/assets/images/playground_16px.png'
import androidLogo from '~/assets/images/android.png'
import appleLogo from '~/assets/images/apple.png'
import webLogo from '~/assets/images/web.png'
import config from '~/config'

const Product = (props: {
  logo: string
  appleLink: string
  androidLink: string
  appRedirect: () => void
  children: ReactNode
}) => {
  const { boxShadows } = useTheme()

  const cardHoverStyle = () => ({ boxShadow: boxShadows['1dp'] })

  const linkHoverStyle = () => ({ cursor: 'pointer' })

  const onLinkClick = (evt: React.MouseEvent, link: string) => {
    evt.stopPropagation()
    window.location.assign(link)
  }

  const linkProps = () => {
    return {
      hoverStyle: linkHoverStyle,
      width: 0.333,
      height: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

  const { logo, appleLink, androidLink, appRedirect, children } = props

  return (
    <Card>
      <Box
        minWidth="264px"
        hoverStyle={cardHoverStyle}
        borderRadius="small"
        paddingTop="medium"
        paddingBottom="medium"
        onClick={appRedirect}>
        <Stack align="center" space="medium">
          <Image src={logo} width={120} height={120} />
          {children}
          <Columns alignY="center">
            <Box
              {...linkProps()}
              onClick={evt => onLinkClick(evt, appleLink)}
              borderRight={'1px solid'}
              borderColor={'lowerEmphasis'}>
              <Image src={appleLogo} width={20} />
            </Box>
            <Box
              {...linkProps()}
              onClick={evt => onLinkClick(evt, androidLink)}
              borderRight={'1px solid'}
              borderColor={'lowerEmphasis'}>
              <Image src={androidLogo} width={20} />
            </Box>
            <Box {...linkProps()} onClick={appRedirect}>
              <Image src={webLogo} width={44} />
            </Box>
          </Columns>
        </Stack>
      </Box>
    </Card>
  )
}

type Props = RouteComponentProps

const ProductSelector = (_props: Props) => {
  const { request, response } = useFetch({ credentials: 'include' })
  const [educatorContexts, setEducatorContexts] = useState<Nullable<Stargate.UserContext[]>>(null)
  const [parentContexts, setParentContexts] = useState<Nullable<Stargate.UserContext[]>>(null)

  const findContexts = (contexts: Stargate.UserContext[], searchTerms: Array<string>) => {
    return contexts.filter(context =>
      searchTerms.some(searchTerm => context.strategy_type.includes(searchTerm))
    )
  }

  const appRedirect = async (context: Stargate.UserContext, appLink: string) => {
    const params = { strategy_type: context.strategy_type, strategy_id: context.strategy_id }

    await request.post('/api/v1/auth/context', params)

    window.location.assign(appLink)
  }

  const homeRedirect = (context: Stargate.UserContext) => appRedirect(context, `${config.HOMEWEB_URL}`)

  const playgroundRedirect = (context: Stargate.UserContext) =>
    appRedirect(context, `${config.PLAYGROUND_URL}`)

  const checkContexts = async () => {
    await request.get('/api/v1/contexts')

    if (response.ok) {
      const contexts: Stargate.UserContext[] = response.data.data.contexts

      const parentContexts = findContexts(contexts, ['Parent', 'Guardian'])

      const educatorContexts = findContexts(contexts, ['Educator'])

      if (parentContexts.length > 0 && educatorContexts.length > 0) {
        setParentContexts(parentContexts)
        setEducatorContexts(educatorContexts)
      } else if (parentContexts.length > 0) {
        homeRedirect(parentContexts[0])
      } else if (educatorContexts.length > 0) {
        playgroundRedirect(educatorContexts[0])
      } else {
        navigate('/hubguest')
      }
    }
  }

  useEffect(() => {
    checkContexts()
  }, [])

  if (parentContexts && educatorContexts) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        padding={['none', 'large']}
        width="100vw">
        <Box maxWidth={['100%', '480px']} width={1}>
          <Columns space="medium">
            <Column>
              <Product
                logo={homeLogo}
                appleLink="https://apps.apple.com/au/app/xplor-home/id1479657396"
                androidLink="https://play.google.com/store/apps/details?id=com.xplor.home"
                appRedirect={() => homeRedirect(parentContexts[0])}>
                <Stack align="center" space="small">
                  <Text weight="extraBold" size="h6">
                    Home
                  </Text>
                  <Text>Parents</Text>
                </Stack>
              </Product>
            </Column>
            <Column>
              <Product
                logo={playgroundLogo}
                appleLink="https://apps.apple.com/au/app/xplor-playground/id1436272895"
                androidLink="https://play.google.com/store/apps/details?id=com.xplor.playground"
                appRedirect={() => playgroundRedirect(educatorContexts[0])}>
                <Stack align="center" space="small">
                  <Text weight="extraBold" size="h6">
                    Playground
                  </Text>
                  <Text>Educators</Text>
                </Stack>
              </Product>
            </Column>
          </Columns>
        </Box>
      </Box>
    )
  } else {
    return <></>
  }
}

export default ProductSelector

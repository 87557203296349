import React, { useContext, useState } from 'react'
import { Router, RouteComponentProps, navigate } from '@reach/router'
import useFetch from 'use-http'

import { getErrorMessage } from '~/api'
import { SessionContext } from '~/contexts/SessionContext'
import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import SetPhone from '~/components/SetPhone'
import EnterPin from '~/components/EnterPin'
import SetPin from '~/components/SetPin'
import UpgradeResentEmail from '~/components/UpgradeResentEmail'
import UpgradeSuccess from '~/components/UpgradeSuccess'
import UpgradePrompt from './UpgradePrompt'
import StatusCheck from './StatusCheck'
import SentEmail from './SentEmail'

type Props = RouteComponentProps

const ManualUpgrade = (_props: Props) => {
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
  const { request, response } = useFetch({ credentials: 'include' })

  const onBack = () => history.back()

  const onPhoneClear = () => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, phone: null })
  }

  const onPhoneNext = async (phone: string) => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, phone: phone })

    await request.post('/api/v1/upgrades/preupgrade', { identifier: phone })

    if (response.ok) {
      navigate(response.data.data === true ? 'enter-pin' : 'set-pin')
    } else {
      navigate('/error')
    }
  }

  const onPinConfirm = async (pin: string) => {
    setErrorMessage(null)

    const params = {
      phone: sessionDetails.phone,
      pin: pin,
    }

    await request.post('/api/v1/upgrades/link', params)

    if (response.ok) {
      const data = response.data.data

      setSessionDetails({
        ...sessionDetails,
        referrer: data.strategy_type === 'Educator' ? 'playground' : 'home-web',
        firstName: data.first_name,
        strategyType: data.strategy_type,
        strategyID: data.strategy_id,
        identifier: data.upgrade_email_address,
      })

      navigate(data.strategy_type === 'Xplorer' ? '/hubguest' : 'success')
    } else if (response.status != 401) {
      navigate('/error')
    } else {
      setErrorMessage(getErrorMessage(response))
    }
  }

  const onPinCreate = async (pin: string) => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, pin: pin })

    const params = {
      phone: sessionDetails.phone,
      pin: pin,
    }

    await request.post('/api/v1/upgrades/manual/request', params)

    if (response.ok) {
      const data = response.data.data

      if (data.upgrade_status === 'completed') {
        setSessionDetails({
          ...sessionDetails,
          referrer: data.strategy_type === 'Educator' ? 'playground' : 'home-web',
          firstName: data.first_name,
          strategyType: data.strategy_type,
          strategyID: data.strategy_id,
          identifier: data.upgrade_email_address,
        })

        navigate('success')
      } else if (data.upgrade_status === 'sent') {
        setSessionDetails({ ...sessionDetails, identifier: data.upgrade_email_address })
        navigate('sent-email')
      } else {
        navigate('/error')
      }
    } else {
      setErrorMessage(getErrorMessage(response))
    }
  }

  return (
    <CenteredContentLayout>
      <Router>
        <StatusCheck path="/" />
        <UpgradePrompt path="prompt" />
        <SetPhone onClear={onPhoneClear} onBack={onBack} onNext={onPhoneNext} path="phone" />
        <EnterPin errorMessage={errorMessage} onBack={onBack} onNext={onPinConfirm} path="enter-pin" />
        <SetPin errorMessage={errorMessage} onBack={onBack} onNext={onPinCreate} path="set-pin" />
        <UpgradeSuccess path="success" />
        <SentEmail path="sent-email" />
        <UpgradeResentEmail path="resent-email" />
      </Router>
    </CenteredContentLayout>
  )
}

export default ManualUpgrade

import React, { useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Anchor, Box, Button, Form, Stack, Text, TextField } from '@myxplor/stardust'

import { getErrorMessage } from '~/api'

import { isEmailValid, isPhoneValid } from '../../utils'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import EnvelopeIcon from '~/icons/Envelope'

type Props = RouteComponentProps

const ResetPassword = (_props: Props) => {
  const [identifier, setIdentifier] = useState('')
  const [emailError, setEmailError] = useState(false)
  const { error, request, loading, response } = useFetch()
  const [showError, setShowError] = useState(true)
  const body = { user: identifier, reset_type: 'email' }
  const errorMessage = getErrorMessage(error)
  const validEmail = isEmailValid(body.user)
  const validPhoneNumber = isPhoneValid(body.user)

  const onBack = () => history.back()

  const onForgotEmail = () => {
    navigate('/account-recovery/forgot-email')
  }

  async function onSubmit() {
    if (validEmail || validPhoneNumber) {
      setEmailError(false)
      await request.post('/api/v1/send-password-reset', body)

      if (response.ok) {
        navigate('/account-recovery/check-inbox', { state: { resetType: 'email' } })
      } else {
        setShowError(true)
      }
    } else {
      setEmailError(true)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        <Header icon="back" onBack={onBack}>
          Reset Password
        </Header>

        <Content>
          <Stack align="center" space="large">
            <Text color="higherEmphasis" prefab="body">
              Enter your email address or phone number associated with your account and we&apos;ll send you a
              reset link to reset your password.
            </Text>
            <TextField
              label="Mobile Number or Email"
              name="identifier"
              value={identifier}
              invalid={error && showError}
              trailingIcon={
                <Box height={24} width={24} color="primary">
                  <EnvelopeIcon />
                </Box>
              }
              onChange={(value: string) => {
                setIdentifier(value)
                setShowError(false)
              }}
            />

            {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}
            {emailError && <Alert tone="negative">Please check email or phone format.</Alert>}

            <Anchor onClick={onForgotEmail}>Forgot email?</Anchor>
          </Stack>
        </Content>

        <Footer>
          <Button type="submit" disabled={!identifier || loading} fill size="large">
            Send Reset Link
          </Button>
        </Footer>
      </Container>
    </Form>
  )
}

export default ResetPassword

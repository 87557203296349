import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Anchor, Box, Stack, Text } from '@myxplor/stardust'
import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'
import EmailIcon from '~/icons/Email'

type Props = RouteComponentProps

const Success = (_props: Props) => {
  const [{ identifier, phone, pin }] = useContext(SessionContext)
  const { request, response } = useFetch()

  const onResend = async () => {
    await request.post('/api/v1/upgrades/manual/request', { phone, pin })

    if (!response.ok) {
      navigate('/error')
    }
  }

  return (
    <Container>
      <Header icon="close" onBack={() => navigate('/')}>
        Check your Inbox
      </Header>
      <Content>
        <Stack align="center" space="xxxlarge">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Stack align="center" space="large">
            <Text color="higherEmphasis" prefab="body">
              We’ve sent you a verification email at {identifier}. Please click it to finish.
            </Text>
            <Text color="higherEmphasis" prefab="body">
              Cant find it? Check your junk folder or{' '}
              <Anchor color="primary" prefab="body" onClick={onResend}>
                Resend
              </Anchor>
              .
            </Text>
          </Stack>
        </Stack>
      </Content>
    </Container>
  )
}

export default Success

const palette = {
  cosmicShade0: 'white',
  cosmicShade1: 'rgba(255, 255, 255, 0.65)',
  cosmicShade2: 'rgba(255, 255, 255, 0.38)',
  cosmicShade3: 'rgb(250, 250, 250)',
  cosmicShade4: 'rgb(235, 235, 235)',
  cosmicShade5: '#3B3C3F',
  cosmicShade6: 'rgba(0, 0, 0, .12)',
  cosmicShade7: 'rgba(0, 0, 0, .38)',
  cosmicShade8: 'rgba(0, 0, 0, .65)',
  cosmicShade9: 'rgba(0, 0, 0, .8)',
}

export default {
  ...palette,
  cosmicShade: palette.cosmicShade9,
}

import React, { useContext, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import {
  Alert,
  Anchor,
  Box,
  Form,
  Image,
  Button,
  PageHeading,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import { getErrorMessage } from '~/api'
import config from '~/config'

import HeroImageLayout from '~/layouts/HeroImageLayout'
import splash from '~/assets/images/admin_splash.png'

import { AuthContext } from '~/contexts/AuthContext'

import xplorRocketImage from '~/assets/images/xplor_rocket.png'

import VisibilityOffIcon from '~/icons/VisibilityOff'
import VisibilityOnIcon from '~/icons/VisibilityOn'

type Props = RouteComponentProps

const AdminLogin = (_props: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const { request, error, loading } = useFetch(config.HODOR_API)
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showError, setShowError] = useState(true)
  const errorMessage = getErrorMessage(error)

  const onSubmit = () => {
    if (identifier && password) {
      const body = { user: identifier, password: password, strategies: ['SuperAdmin'] }

      request.post('/api/v1/auth', body).then(({ data: { token: token } }) => {
        setAuthContext(token, true)
        navigate('/admin/ops/xplor-id')
      })

      setShowError(true)
    }
  }

  const onTogglePasswordMask = () => setShowPassword(!showPassword)

  return (
    <HeroImageLayout image={splash}>
      <Form onSubmit={onSubmit}>
        <Stack align="center" space="large">
          <PageHeading overline="Support Tools">Login</PageHeading>
          <Box paddingBottom="large" paddingTop="xlarge">
            <Box width={72}>
              <Image src={xplorRocketImage} />
            </Box>
          </Box>

          {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}

          <Stack space="small">
            <TextField
              label="Email"
              name="identifier"
              value={identifier}
              type="text"
              onChange={(value: string) => {
                setShowError(false)
                setIdentifier(value)
              }}
            />
            <TextField
              label="Password"
              name="password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={(value: string) => {
                setShowError(false)
                setPassword(value)
              }}
              trailingIcon={
                <Box height={24} width={24} color="primary" onClick={onTogglePasswordMask}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                </Box>
              }
            />
          </Stack>

          <Button disabled={!identifier || !password || loading} fill size="large" type="submit">
            Login
          </Button>
        </Stack>
      </Form>

      <Box bottom={0} left={0} paddingTop="large" position={['relative', 'absolute']} right={0}>
        <Stack space="xxsmall">
          <Text prefab="caption">
            View{' '}
            <Anchor
              color="mediumEmphasis"
              decorate="under"
              prefab="caption"
              href="https://www.xplortechnologies.com/us/terms-use">
              Terms and Conditions
            </Anchor>{' '}
            and{' '}
            <Anchor
              color="mediumEmphasis"
              decorate="under"
              prefab="caption"
              href="https://www.xplortechnologies.com/us/privacy-notice">
              Privacy Statements
            </Anchor>
          </Text>
        </Stack>
      </Box>
    </HeroImageLayout>
  )
}

AdminLogin.displayName = 'AdminLogin'
export default AdminLogin

import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Button, Stack, Text, TextField } from '@myxplor/stardust'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'

type Props = RouteComponentProps

const Welcome = (_props: Props) => {
  const [{ identifier }] = useContext(SessionContext)

  const onBack = () => history.back()

  const onClick = () => {
    history.replaceState(null, '', '/')

    window.location.assign(
      'https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=myxplor.zendesk.com'
    )
  }

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Contact Support
      </Header>
      <Content>
        <Stack align="center" space="large">
          <Text color="higherEmphasis" prefab="body">
            If you already have an account which does not match the email below please contact support.
          </Text>
          <TextField label="Email" name="email" value={identifier || ''} readOnly disabled />
        </Stack>
      </Content>
      <Footer>
        <Stack align="center" space="small">
          <Button fill variant={'outline'} size="large" onClick={onClick}>
            Contact Support
          </Button>
        </Stack>
      </Footer>
    </Container>
  )
}

export default Welcome

import React from 'react'
import { Router, Redirect } from '@reach/router'

import AccountRecovery from '~/pages/AccountRecovery'
import AdminLogin from '~/pages/AdminLogin'
import SsoLogin from '~/pages/SsoLogin'
import ProfileSelector from './ProfileSelector'
import Login from '~/pages/Login'
import Migration from './Migration'
import Onboarding from './Onboarding'
import ServiceLoginOnboarding from './ServiceLoginOnboarding'
import MagicLink from './MagicLink'
import AutoUpgrade from './AutoUpgrade'
import MergeXplorIds from './MergeXplorIds'
import NoContexts from './NoContexts'
import ManualUpgradeVerify from './ManualUpgradeVerify'
import DeprecatedFeature from './DeprecatedFeature'
import Error from './Error'

const RouterUnauthenticated = () => (
  <Router>
    <AdminLogin path="admin/" />
    <AdminLogin path="admin/login/" />
    <Login path="/" />
    <Login path="login/" />
    <SsoLogin path="sso/" />
    <ProfileSelector path="profile-selection/*" />
    <AccountRecovery path="account-recovery/*" />
    <Migration path="migration/*" />
    <MagicLink path="magic-link/*" />
    <MergeXplorIds path="merge-xplor-ids/*" />
    <AutoUpgrade path="auto-upgrade" />
    <Onboarding path="onboarding/*" />
    <ServiceLoginOnboarding path="service-login-onboarding/*" />
    <NoContexts path="/no-services" />
    <ManualUpgradeVerify path="/manual-upgrade/verify" />
    <DeprecatedFeature path="/link-accounts" />
    <Error path="error" />
    <Redirect from="*" to="/" default noThrow />
  </Router>
)

export default RouterUnauthenticated

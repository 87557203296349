// Consumed in the "<UseFetchProvider>" in Application.js
import config from '~/config'
import * as R from 'ramda'
import { isString, getInObject, mergeObjects } from './utils'

// Configuration for use with the 'use-http' 'useFetch' hook we're using to fetch
// Data. generateOptions(token) provides us an options object with authorization
// headers, as well as custom interceptors
// See: https://github.com/alex-cory/use-http

const interceptors = {
  response: (response: Response) => {
    // If token expired on any req redirect to login
    if (
      response.status === 401 &&
      !R.includes('/migrations/ping', response.url) &&
      R.pathEq(['data', 'errors'], 'Token expired', response)
    ) {
      window.location.replace('/')
      return response
    }

    // If response.status ! 200-299, return the error payload we expect as "error"
    if (!response.ok) throw R.pathOr(response, ['data', 'errors', 'detail'], response)

    // This must remain or useFetch breaks
    return response
  },
}

export const baseEndpoint = config.STARGATE_API

export const generateOptions = (token: Nullable<Stargate.AuthToken>): any => {
  const base = { 'Content-Type': 'application/json' }
  const auth = token ? { Authorization: `Bearer ${token}` } : {}

  return {
    cachePolicy: 'network-only',
    headers: mergeObjects(base, auth),
    interceptors: interceptors,
  }
}

export const generateCustomTokenOptions = (
  token: Nullable<Stargate.AuthToken>,
  realm?: Nullable<Stargate.AuthToken>
) => {
  const authHeader = realm ? `Bearer realm="${realm}" ${token}` : `Bearer ${token}`

  return {
    headers: { 'Content-Type': 'application/json', Authorization: authHeader },
    interceptors: interceptors,
  }
}

export const getErrorMessage = (response: any) => {
  const error = getInObject(response, ['data', 'errors'])

  if (isString(error)) {
    return error
  } else if (error && isString(error.detail)) {
    return error.detail
  } else if (error) {
    'Something went wrong. Please contact support.'
  } else {
    return null
  }
}

export const getErrorStatus = (response: any) => {
  return getInObject(response, 'status')
}

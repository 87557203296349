export const PIN_LENGTH = 4

// Copied from playground/src/components/ColorPicker.tsx
export const playgroundColors: Record<string, Record<string, string>> = {
  plasmaCyan: {
    plasmaCyan0: '#E2FCFA',
    plasmaCyan1: '#B7F8F2',
    plasmaCyan2: '#86F4EC',
    plasmaCyan3: '#54EEE7',
    plasmaCyan4: '#32E7E3',
    plasmaCyan5: '#15E2E2',
    plasmaCyan6: '#12D0CF',
    plasmaCyan7: '#16B9B5',
    plasmaCyan8: '#18A49D',
    plasmaCyan9: '#1B7F72',
  },
  auroraGreen: {
    auroraGreen0: '#F0FCEA',
    auroraGreen1: '#DBF7CB',
    auroraGreen2: '#C2F1AA',
    auroraGreen3: '#A8EB85',
    auroraGreen4: '#92E567',
    auroraGreen5: '#80DF4E',
    auroraGreen6: '#70CE46',
    auroraGreen7: '#5AB93B',
    auroraGreen8: '#45A433',
    auroraGreen9: '#188022',
  },
  gaiaGreen: {
    gaiaGreen0: '#DEF2E9',
    gaiaGreen1: '#AFDEC9',
    gaiaGreen2: '#79C8A6',
    gaiaGreen3: '#35B384',
    gaiaGreen4: '#00A46D',
    gaiaGreen5: '#009455',
    gaiaGreen6: '#00864B',
    gaiaGreen7: '#00753F',
    gaiaGreen8: '#006533',
    gaiaGreen9: '#00461E',
  },
  nebulaBlue: {
    nebulaBlue0: '#E0F2F1',
    nebulaBlue1: '#B2DEDC',
    nebulaBlue2: '#80CAC5',
    nebulaBlue3: '#4CB4AD',
    nebulaBlue4: '#25A49B',
    nebulaBlue5: '#009489',
    nebulaBlue6: '#00877C',
    nebulaBlue7: '#01776C',
    nebulaBlue8: '#02675D',
    nebulaBlue9: '#024B40',
  },
  neptuneBlue: {
    neptuneBlue0: '#E3F3FF',
    neptuneBlue1: '#BBE1FF',
    neptuneBlue2: '#8CCFFF',
    neptuneBlue3: '#55BCFF',
    neptuneBlue4: '#1BACFF',
    neptuneBlue5: '#009DFF',
    neptuneBlue6: '#008DFF',
    neptuneBlue7: '#007AFF',
    neptuneBlue8: '#1368EC',
    neptuneBlue9: '#2045CD',
  },
  pulsarPurple: {
    pulsarPurple0: '#F1E6FD',
    pulsarPurple1: '#DAC2FB',
    pulsarPurple2: '#C199F9',
    pulsarPurple3: '#A76CF8',
    pulsarPurple4: '#9146F6',
    pulsarPurple5: '#7A11F2',
    pulsarPurple6: '#6E08EC',
    pulsarPurple7: '#5D00E3',
    pulsarPurple8: '#4A00DE',
    pulsarPurple9: '#2000D9',
  },
  neonPink: {
    neonPink0: '#FFE3FE',
    neonPink1: '#FFB8FD',
    neonPink2: '#FF81FD',
    neonPink3: '#F63FF5',
    neonPink4: '#E900E8',
    neonPink5: '#DC00DD',
    neonPink6: '#CB00D8',
    neonPink7: '#B300D3',
    neonPink8: '#9F00CD',
    neonPink9: '#7700C3',
  },
  solYellow: {
    solYellow0: '#FFFCE5',
    solYellow1: '#FFF7BF',
    solYellow2: '#FFF195',
    solYellow3: '#FEEB6B',
    solYellow4: '#FDE64A',
    solYellow5: '#FBE124',
    solYellow6: '#FCD123',
    solYellow7: '#FAB91D',
    solYellow8: '#F8A017',
    solYellow9: '#F4760C',
  },
  hullOrange: {
    hullOrange0: '#FEF2E0',
    hullOrange1: '#FCDEB1',
    hullOrange2: '#FAC87F',
    hullOrange3: '#F9B24C',
    hullOrange4: '#F7A126',
    hullOrange5: '#F69202',
    hullOrange6: '#F28602',
    hullOrange7: '#EC7602',
    hullOrange8: '#E66702',
    hullOrange9: '#DC4C02',
  },

  superGiantRed: {
    superGiantRed0: '#FCE6EC',
    superGiantRed1: '#F8C0CF',
    superGiantRed2: '#F498B0',
    superGiantRed3: '#EF7292',
    superGiantRed4: '#EA587B',
    superGiantRed5: '#E64766',
    superGiantRed6: '#D54263',
    superGiantRed7: '#BF3D5D',
    superGiantRed8: '#AA3859',
    superGiantRed9: '#833050',
  },

  defaultColors: {
    white: '#FFFFFF',
    black: '#000000',
  },
}

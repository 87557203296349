import React, { ReactNode } from 'react'

import RPath from 'ramda/src/path'

import homeTheme from '~/components/theme/home'
import playgroundTheme from '~/components/theme/playground'
import { Theme } from '~/components/theme/types'
import webViewTheme from '~/components/theme/webview'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import { LAYERS } from '~/theme'

const fontFamily = RPath<string>(['theme', 'fontFamily'])
const primaryColor = RPath<string>(['theme', 'colors', 'primary'])
const selectionColor = RPath<string>(['theme', 'colors', 'selection'])

interface Props {
  children: ReactNode
}

const isWebView = false
const appName = Theme.playground

const GlobalStyle = createGlobalStyle`
  /* @rmwc theme can be set as CSS variables on root, this is all the theme provider does */
  :root {
    --mdc-theme-primary: ${primaryColor};
    --mdc-theme-secondary: ${primaryColor};
    --mdc-theme-on-surface: white;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-size: 16px;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    background-color: ${isWebView ? '#242527' : '#ffffff'};
    color: rgba(0, 0, 0, 0.8);
    font-family: ${fontFamily};
    font-size: 1rem;
    font-weight: normal;
    line-height: ${isWebView ? 1.6 : 1};
    margin: 0;
    padding: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }

  hr {
    border: 0;
    margin: 0;
    padding: 0;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input,
  button,
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 0;
    border-radius: 0;
    line-height: inherit;
    margin: 0;
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
  }

  ::selection {
    background: ${selectionColor};
  }

    /* FullScreen layout is 1000 z-index, +10 to make context menu work */
  .react-contextmenu {
    z-index: ${LAYERS.Dropdown};
  }

  .ReactModal__Overlay {
    overflow-y: hidden;
  }
`

function getAppTheme(name: Theme) {
  switch (name) {
    case Theme.home:
    default:
      return homeTheme
    case Theme.playground:
      return playgroundTheme
    case Theme.webview:
      return webViewTheme
  }
}

const ThemeWrapper = ({ children }: Props) => {
  const theme = getAppTheme(appName)
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeProvider>
  )
}

ThemeWrapper.displayName = 'Theme'
export default ThemeWrapper

import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import CreateAccountForm from '~/pages/CreateAccountForm'
import ResendEmail from '~/pages/ResendEmail'
import Ping from './Ping'

type Props = RouteComponentProps

const Migration = (_props: Props) => {
  const finaliseEndpoint = '/api/v1/migrations/finalise'
  const resendEndpoint = '/api/v1/migrations/send-email'

  return (
    <CenteredContentLayout>
      <Router>
        <Ping path="/" />
        <CreateAccountForm finaliseEndpoint={finaliseEndpoint} path="create-account/*" />
        <ResendEmail resendEndpoint={resendEndpoint} path="resend-email" />
      </Router>
    </CenteredContentLayout>
  )
}

export default Migration

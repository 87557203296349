import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import MagicLinkLoginForm from './MagicLinkLoginForm'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import ExpiredMagicLinkEmail from './ExpiredMagicLinkEmail'
import Success from './Success'

type Props = RouteComponentProps

const MagicLink = (_props: Props) => {
  return (
    <CenteredContentLayout>
      <Router>
        <MagicLinkLoginForm path="/login" />
        <ExpiredMagicLinkEmail path="/expired" />
        <Success path="/success" />
      </Router>
    </CenteredContentLayout>
  )
}

export default MagicLink

import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Alert, Anchor, Box, Button, Stack, Text, TextField } from '@myxplor/stardust'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'
import KeyIcon from '~/icons/Key'
import { filterNonNumeric } from '~/utils'

interface Props extends RouteComponentProps {
  errorMessage?: Nullable<string>
  onNext(pin: string): void
  onBack(): void
}

const SetPin = ({ errorMessage, onNext, onBack }: Props) => {
  const [pin, setPin] = useState('')
  const [displayError, setDisplayError] = useState(false)

  const validatePassword = () => pin.length > 0

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Enter PIN
      </Header>
      <Content>
        <Stack space="large">
          <Text color="higherEmphasis" prefab="body">
            We detected this phone number is already in use. Enter the PIN you previously set now.
          </Text>
          <Stack space="small">
            <TextField
              label="Pin"
              name="xplor_pin"
              type="password"
              value={pin}
              onChange={(value: string) => {
                setDisplayError(false)
                setPin(filterNonNumeric(value))
              }}
              trailingIcon={
                <Box height={24} width={24} color="primary">
                  <KeyIcon />
                </Box>
              }
            />
            {displayError && errorMessage && <Alert tone="negative">{errorMessage}</Alert>}
            <Text color="higherEmphasis" prefab="subtitle">
              Can’t remember?{' '}
              <Anchor
                color="primary"
                prefab="subtitle"
                href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=myxplor.zendesk.com">
                Chat with support
              </Anchor>
              .
            </Text>
          </Stack>
        </Stack>
      </Content>
      <Footer>
        <Button
          fill
          size="large"
          disabled={!validatePassword()}
          onClick={() => {
            setDisplayError(true)
            onNext(pin)
          }}>
          Next
        </Button>
      </Footer>
    </Container>
  )
}

export default SetPin

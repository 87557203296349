import React, { useState, useEffect } from 'react'
import useFetch from 'use-http'
import { navigate, RouteComponentProps } from '@reach/router'

import { Alert, Anchor, Box, Form, Button, Stack, Text, TextField } from '@myxplor/stardust'

import { generateCustomTokenOptions } from '~/api'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import VisibilityOffIcon from '~/icons/VisibilityOff'
import VisibilityOnIcon from '~/icons/VisibilityOn'

type Props = RouteComponentProps

const MagicLinkLoginForm = (_props: Props) => {
  const [formIdentifier, setFormIdentifier] = useState('')
  const [formPassword, setFormPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [alertMessage, setAlertMessage] = useState<Nullable<string>>(null)
  const [negativeTone, setNegativeTone] = useState(true)
  const magicLinkToken = new URLSearchParams(window.location.search).get('token') as string
  const { request, loading, response } = useFetch(generateCustomTokenOptions(magicLinkToken))

  useEffect(() => {
    fetchEmail()
  }, [])

  const showAlert = (message: string, isNegative: boolean) => {
    setAlertMessage(message)
    setNegativeTone(isNegative)
  }

  const showPositiveAlert = (message: string) => showAlert(message, false)

  const showNegativeAlert = (message: string) => showAlert(message, true)

  async function fetchEmail() {
    await request.get('/api/v1/contexts/magic-link/user')

    if (response.ok) {
      setFormIdentifier(response.data.data)
    } else {
      navigate('/magic-link/expired')
    }
  }

  const onClose = () => {
    history.replaceState(null, '', '/')
    navigate('/')
  }

  async function onSubmit() {
    const body = {
      identifier: formIdentifier,
      password: formPassword,
    }
    setAlertMessage(null)

    if (formIdentifier && formPassword) {
      await request.post('/api/v1/contexts/magic-link/finalise', body)

      if (response.ok) {
        navigate('/magic-link/success')
      } else if (response.status == 401) {
        showNegativeAlert('Incorrect password. Please try again or press forgot password')
      } else {
        showNegativeAlert('Something has gone wrong. Please contact support.')
      }
    }
  }

  const onTogglePasswordMask = () => {
    setShowPassword(!showPassword)
  }

  async function onForgotPassword() {
    const body = { user: formIdentifier, reset_type: 'email' }

    await request.post('/api/v1/send-password-reset', body)

    if (response.ok) {
      showPositiveAlert(
        'Reset password email sent. Please set a new password and return to this page when finished.'
      )
    } else {
      showNegativeAlert('Something has gone wrong. Please contact support.')
    }
  }

  if (loading && formIdentifier === '') {
    return <></>
  } else {
    return (
      <Form onSubmit={onSubmit}>
        <Container>
          <Header icon="close" onBack={onClose}>
            Login
          </Header>
          <Box paddingLeft="large" paddingRight="large">
            <Text size="h6" lineHeight="1.5">
              This is the account your profile will be merged into.
            </Text>
          </Box>
          <Content>
            <Stack align="center" space="small">
              {alertMessage && <Alert tone={negativeTone ? 'negative' : 'positive'}>{alertMessage}</Alert>}

              <TextField label="Email" name="identifier" value={formIdentifier} type="text" readOnly={true} />
              <TextField
                label="Password"
                name="password"
                value={formPassword}
                type={showPassword ? 'text' : 'password'}
                onChange={(value: string) => {
                  setFormPassword(value)
                }}
                trailingIcon={
                  <Box height={24} width={24} color="primary" onClick={onTogglePasswordMask}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  </Box>
                }
              />
              <Anchor onClick={onForgotPassword}>Forgot Password?</Anchor>
            </Stack>
          </Content>

          <Footer>
            <Button disabled={loading} fill size="large" type="submit">
              Login
            </Button>
          </Footer>
        </Container>
      </Form>
    )
  }
}

export default MagicLinkLoginForm

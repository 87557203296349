import React from 'react'

const ChevronLeft = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9407 9.88L19.0607 8L11.0607 16L19.0607 24L20.9407 22.12L14.834 16L20.9407 9.88Z"
      fill="currentColor"
    />
  </svg>
)

export default React.memo(ChevronLeft)

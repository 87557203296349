import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Animation, Box, Stack, Text } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'

import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'

type Props = RouteComponentProps

const AllDone = (_props: Props) => {
  const onDone = () => {
    navigate('/')
  }

  return (
    <Container>
      <Header icon="back" onBack={onDone}>
        All Done.
      </Header>

      <Content>
        <Stack align="center" space="small">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>

          <Text color="higherEmphasis" prefab="body">
            Your accounts have been merged.
          </Text>
        </Stack>
      </Content>
    </Container>
  )
}

export default AllDone

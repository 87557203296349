import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Anchor, Box, Image, Button, PageHeading, Stack, Text, TextField } from '@myxplor/stardust'

import config from '~/config'

import HeroImageLayout from '~/layouts/HeroImageLayout'
import splash from '~/assets/images/admin_splash.png'

import xplorRocketImage from '~/assets/images/xplor_rocket.png'

type Props = RouteComponentProps

const get_error_from_params = (): Nullable<string> => {
  return new URLSearchParams(window.location.search).get('error') as Nullable<string>
}
const get_app_ref_from_params = (): string => {
  return new URLSearchParams(window.location.search).get('app') || 'stargate-client-web'
}
const get_client_state_from_params = (): Nullable<string> => {
  return new URLSearchParams(window.location.search).get('state') as Nullable<string>
}
const get_code_challenge_from_params = (): Nullable<string> => {
  return new URLSearchParams(window.location.search).get('code_challenge') as Nullable<string>
}
const get_code_challenge_method_from_params = (): Nullable<string> => {
  return new URLSearchParams(window.location.search).get('code_challenge_method') as Nullable<string>
}
const get_service_fkey_from_params = (): Nullable<string> => {
  return new URLSearchParams(window.location.search).get('service_fkey') as Nullable<string>
}

const SsoLogin = (_props: Props) => {
  const [email, setEmail] = useState<string | number>('')
  const [showError, setShowError] = useState(true)
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(get_error_from_params())

  const { request, loading } = useFetch(config.HODOR_API, { credentials: 'include' })

  const onSubmit = (e: any) => {
    e.preventDefault()
    setShowError(false)
    if (!email) {
      return
    }

    const app_referrer = get_app_ref_from_params()
    const state = get_client_state_from_params()
    const code_challenge = get_code_challenge_from_params()
    const code_challenge_method = get_code_challenge_method_from_params()
    const service_fkey = get_service_fkey_from_params()

    const body = {
      email: email,
      app_referrer: app_referrer,
      state: state,
      code_challenge: code_challenge,
      code_challenge_method: code_challenge_method,
      service_fkey: service_fkey,
    }

    request
      .post('/sso/login-method', body)
      .then(resp => {
        if (resp.error) {
          setErrorMessage(resp.error.message)
          return setShowError(true)
        }
        window.location.href = resp.data.url
      })
      .catch(e => {
        console.log('An error occured,', e.message)
      })
  }

  return (
    <HeroImageLayout image={splash}>
      <form onSubmit={onSubmit}>
        <Stack align="center" space="medium">
          <PageHeading overline="Single Sign On">Login</PageHeading>
          <Box paddingBottom="large" paddingTop="medium">
            <Box width={72}>
              <Image src={xplorRocketImage} />
            </Box>
          </Box>
          <TextField
            type="email"
            label="Email"
            name="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={setEmail}
          />

          {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}

          <Button disabled={loading || !email} fill size="large" type="submit">
            Login with SSO
          </Button>
        </Stack>
      </form>

      <Box
        display="flex"
        bottom={0}
        left={0}
        paddingTop="xlarge"
        position={['relative', 'absolute']}
        right={0}>
        <Stack space="xxsmall">
          <Text prefab="caption">
            View{' '}
            <Anchor
              color="mediumEmphasis"
              decorate="under"
              prefab="caption"
              href="https://www.xplortechnologies.com/us/terms-use">
              Terms and Conditions
            </Anchor>{' '}
            and{' '}
            <Anchor
              color="mediumEmphasis"
              decorate="under"
              prefab="caption"
              href="https://www.xplortechnologies.com/us/privacy-notice">
              Privacy Statements
            </Anchor>
          </Text>
        </Stack>
      </Box>
    </HeroImageLayout>
  )
}

SsoLogin.displayName = 'SsoLogin'
export default SsoLogin

import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import { isAndroid, isIOS } from 'react-device-detect'

import { Animation, Box, Button, Image, Inline, Stack, Text, TextField } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'
import { prettyNamePrint, redirect } from '~/utils'

type Props = RouteComponentProps

const Link = ({ link, src }: { link: string; src: string }) => {
  return (
    <Box
      hoverStyle={() => ({ cursor: 'pointer' })}
      width="200px"
      height="60px"
      onClick={() => window.location.assign(link)}>
      <Image src={src} />
    </Box>
  )
}

const Success = (_props: Props) => {
  const [sessionDetails] = useContext(SessionContext)

  const isMobile = isAndroid || isIOS
  const isEducator = sessionDetails.strategyType?.includes('Educator')
  const isParent =
    sessionDetails.strategyType === 'Parent' || sessionDetails.strategyType?.includes('Guardian')
  const isExternalUser = !!sessionDetails.external

  const parentAndroidRedirect = isParent && (isAndroid || isExternalUser)
  const parentIOSRedirect = isParent && (isIOS || isExternalUser)
  const parentAppRedirect = parentAndroidRedirect || parentIOSRedirect

  const onRedirect = () => {
    redirect(sessionDetails)
  }

  const serviceName = sessionDetails.serviceNames && sessionDetails.serviceNames[0]

  const webRedirect =
    (sessionDetails.strategyType === 'Educator' && sessionDetails.redirect) ||
    (sessionDetails.strategyType === 'Parent' && !isMobile)

  let message = `You are now a proud new member of ${serviceName}. For all future logins please use the email below.`
  if (isExternalUser && isParent)
    message = 'You are now ready to use Home. For all future logins please use the email below.'
  if (isExternalUser && isEducator)
    message = 'You are now ready to use Playground. For all future logins please use the email below.'

  return (
    <Container>
      <Header icon={webRedirect ? 'close' : undefined} onBack={onRedirect}>
        {`Welcome ${prettyNamePrint(sessionDetails.firstName)}`}
      </Header>
      <Content>
        <Stack align="center" space="large">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            {message}
          </Text>
          <TextField label="Email" name="email" value={sessionDetails.identifier || ''} readOnly disabled />
        </Stack>
      </Content>
      <Footer>
        {parentAppRedirect && (
          <Stack space="medium" align="center">
            <Text color="higherEmphasis" prefab="body" align="center">
              Download the Home app to access great features for families.
            </Text>

            <Inline align="center" space="small">
              {parentAndroidRedirect && (
                <Link
                  link="https://play.google.com/store/apps/details?id=com.xplor.home"
                  src="https://dizo3j8jnw9cn.cloudfront.net/emails/android.png"
                />
              )}
              {parentIOSRedirect && (
                <Link
                  link="https://apps.apple.com/au/app/xplor-home/id1479657396"
                  src="https://dizo3j8jnw9cn.cloudfront.net/emails/apple.png"
                />
              )}
            </Inline>
          </Stack>
        )}
        {webRedirect && (
          <Button fill size="large" onClick={onRedirect}>
            Finished
          </Button>
        )}
      </Footer>
    </Container>
  )
}

export default Success

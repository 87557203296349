import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'

import MergeXplorIdsLoginForm from './MergeXplorIdsLoginForm'
import ExpiredMergeXplorIdsEmail from './ExpiredMergeXplorIdsEmail'
import AllDone from './AllDone'

type Props = RouteComponentProps

const MergeXplorIds = (_props: Props) => {
  return (
    <CenteredContentLayout>
      <Router>
        <MergeXplorIdsLoginForm path="/login" />
        <ExpiredMergeXplorIdsEmail path="/expired" />
        <AllDone path="/all-done" />
      </Router>
    </CenteredContentLayout>
  )
}

export default MergeXplorIds

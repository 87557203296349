import React, { ReactNode } from 'react'

import { Box, Color, Heading, Space, Stack, Text, ThemeableResponsive } from '@myxplor/stardust'

import ChevronLeftIcon from '~/icons/ChevronLeft'
import CloseIcon from '~/icons/Close'

const Container = ({ children }: { children: ReactNode }) => (
  <Box display="flex" flexDirection="column" minHeight={['100vh', '720px']} width={1}>
    {children}
  </Box>
)

interface HeaderProps {
  children: ReactNode
  color?: Color
  icon?: 'back' | 'close'
  iconPadding?: ThemeableResponsive<Space>
  underline?: string
  overline?: string
  onBack?(): void
}

const hoverStyle = () => ({ opacity: 0.66 })

const Header = ({ children, color, icon, iconPadding, underline, overline, onBack }: HeaderProps) => {
  const resolvedColor = color || 'higherEmphasis'
  return (
    <Box padding={['medium', 'large']}>
      <Stack space="xxsmall">
        <Box
          paddingBottom={iconPadding}
          color={resolvedColor}
          cursor="pointer"
          height={32}
          hoverStyle={hoverStyle}
          marginLeft={-6}
          transition="opacity 0.2s"
          width={32}
          onClick={onBack}>
          {icon == 'back' && <ChevronLeftIcon />}
          {icon == 'close' && <CloseIcon />}
        </Box>

        {overline && (
          <Text color="higherEmphasis" size="overline" weight="semiBold" letterSpacing={'3.2'}>
            {overline}
          </Text>
        )}

        <Heading color={resolvedColor} level={4} lineHeight={1.25} weight="bold">
          {children}
        </Heading>

        {underline && (
          <Text color={resolvedColor} lineHeight={1} prefab="body">
            {underline}
          </Text>
        )}
      </Stack>
    </Box>
  )
}

interface ContentProps {
  children: ReactNode
}

const Content = ({ children }: ContentProps) => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    flexGrow={1}
    justifyContent="center"
    width={1}>
    <Box padding={['medium', 'large']} width={1}>
      {children}
    </Box>
  </Box>
)

interface FooterProps {
  children?: ReactNode
}

const Footer = ({ children }: FooterProps) => <Box padding={['small', 'medium']}>{children}</Box>

export { Container, Content, Header, Footer }

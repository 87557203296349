import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Box, Stack, Text, TextField } from '@myxplor/stardust'
import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'
import EmailIcon from '~/icons/Email'

import { SessionContext } from '~/contexts/SessionContext'

const ResentEmail = (_props: RouteComponentProps) => {
  const [{ identifier }] = useContext(SessionContext)

  const onBack = () => navigate('/')

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Account Update
      </Header>
      <Content>
        <Stack align="center" space="xxlarge">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Stack space="large">
            <Text color="highestEmphasis" prefab="body">
              We have noticed that your email address has recently changed. We have sent an email to your new
              address below. Please follow the instructions to upgrade your account.
            </Text>
            <TextField label="Email" name="email" value={identifier || ''} readOnly disabled />
          </Stack>
        </Stack>
      </Content>
      <Footer />
    </Container>
  )
}

export default ResentEmail

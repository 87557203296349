import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Button, Stack, Text } from '@myxplor/stardust'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'

type Props = RouteComponentProps

const Welcome = (_props: Props) => {
  const [{ serviceNames, ssoOnly }] = useContext(SessionContext)

  const onBack = () => navigate('/')

  const onCreateAccount = () =>
    ssoOnly ? navigate('create-account/set-pin') : navigate('create-account/set-password')

  const onExistingAccount = () => {
    navigate('existing-account')
  }

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Welcome to Xplor
      </Header>
      <Content>
        <Stack align="center" space="large">
          <Text color="higherEmphasis" prefab="body">
            {`We are always super excited to welcome new users to Xplor and ${serviceNames &&
              serviceNames[0]}`}
            .
          </Text>
          <Text color="higherEmphasis" prefab="body">
            We will now take you through a few simple steps to verify your email and set up your new account.
          </Text>
        </Stack>
      </Content>
      <Footer>
        <Stack align="center" space="small">
          <Button fill size="large" onClick={onCreateAccount}>
            Create Account
          </Button>
          <Button fill variant={'outline'} size="large" onClick={onExistingAccount}>
            I already have an account
          </Button>
        </Stack>
      </Footer>
    </Container>
  )
}

export default Welcome

import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import Status from './Status'
import SetPasswordForm from './SetPasswordForm'
import Success from './Success'
import ExpiredLink from './ExpiredLink'

type Props = RouteComponentProps

const ServiceLoginOnboarding = (_props: Props) => {
  return (
    <CenteredContentLayout>
      <Router>
        <Status path="/" />
        <SetPasswordForm path="set-password" />
        <Success path="success" />
        <ExpiredLink path="expired" />
      </Router>
    </CenteredContentLayout>
  )
}

export default ServiceLoginOnboarding

import React from 'react'

const MobileInfo = () => (
  <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.942 24.574C14.7709 23.4025 14.7709 21.5035 15.942 20.332V20.332C16.505 19.7693 16.8214 19.006 16.8214 18.21C16.8214 17.414 16.505 16.6507 15.942 16.088L10.638 10.786C9.4665 9.61487 7.5675 9.61487 6.396 10.786L5.136 12.044C2.15138 15.0277 1.64079 19.6845 3.908 23.244L3.92 23.264C9.73237 31.8636 17.1394 39.2692 25.74 45.08L25.76 45.094C29.32 47.3607 33.9768 46.8493 36.96 43.864L38.218 42.606C38.781 42.0433 39.0974 41.28 39.0974 40.484C39.0974 39.688 38.781 38.9247 38.218 38.362L32.912 33.06C32.3493 32.497 31.586 32.1807 30.79 32.1807C29.994 32.1807 29.2307 32.497 28.668 33.06V33.06C27.4965 34.2312 25.5975 34.2312 24.426 33.06"
      fill="#7BCAC5"
    />
    <path
      d="M15.942 24.574C14.7709 23.4025 14.7709 21.5035 15.942 20.332V20.332C16.505 19.7693 16.8214 19.006 16.8214 18.21C16.8214 17.414 16.505 16.6507 15.942 16.088L10.638 10.786C9.4665 9.61487 7.5675 9.61487 6.396 10.786L5.136 12.044C2.15138 15.0277 1.64079 19.6845 3.908 23.244L3.92 23.264C9.73237 31.8636 17.1394 39.2692 25.74 45.08L25.76 45.094C29.32 47.3607 33.9768 46.8493 36.96 43.864L38.218 42.606C38.781 42.0433 39.0974 41.28 39.0974 40.484C39.0974 39.688 38.781 38.9247 38.218 38.362L32.912 33.06C32.3493 32.497 31.586 32.1807 30.79 32.1807C29.994 32.1807 29.2307 32.497 28.668 33.06V33.06C27.4965 34.2312 25.5975 34.2312 24.426 33.06"
      stroke="#535364"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5 1.5C31.2376 1.50024 27.2952 3.7615 25.1429 7.44059C22.9906 11.1197 22.9514 15.6644 25.04 19.38L22 28.5L30.852 24.566C35.333 26.4455 40.5052 25.4371 43.952 22.0119C47.3988 18.5868 48.4398 13.421 46.5885 8.92825C44.7372 4.43548 40.3592 1.50263 35.5 1.5Z"
      fill="white"
      stroke="#535364"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M34.75 14V8" stroke="#535364" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M34.75 17.75C34.3358 17.75 34 18.0858 34 18.5C34 18.9142 34.3358 19.25 34.75 19.25C35.1642 19.25 35.5 18.9142 35.5 18.5C35.5 18.0858 35.1642 17.75 34.75 17.75V17.75"
      stroke="#535364"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default React.memo(MobileInfo)

const palette = {
  superGiantRed0: '#FCE6EC',
  superGiantRed1: '#F8C0CF',
  superGiantRed2: '#F498B0',
  superGiantRed3: '#EF7292',
  superGiantRed4: '#EA587B',
  superGiantRed5: '#E64766',
  superGiantRed6: '#D54263',
  superGiantRed7: '#BF3D5D',
  superGiantRed8: '#AA3859',
  superGiantRed9: '#833050',
}

export default {
  ...palette,
  superGiantRed: palette.superGiantRed6,
}

import React from 'react'

const Logo = () => (
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.591 0C93.5967 3.26975 56.9482 41.0082 45.7765 58.8556C22.5613 54.9319 5.85826 64.8501 0.408669 70.2997V79.7003C55.1771 82.5613 71.5258 127.52 70.2997 150H80.1089C93.188 135.613 93.188 113.488 91.5531 104.223C137.984 73.8147 149.591 22.0708 149.591 0ZM117.711 50.2721C117.711 59.7527 110.026 67.4383 100.545 67.4383C91.0643 67.4383 83.3787 59.7527 83.3787 50.2721C83.3787 40.7914 91.0643 33.1059 100.545 33.1059C110.026 33.1059 117.711 40.7914 117.711 50.2721ZM37.6022 131.199C37.6022 141.032 30.054 149.101 20.436 149.93V150H0V131.199L1.1445e-05 131.199C1.1445e-05 120.815 8.41754 112.398 18.8011 112.398C29.1847 112.398 37.6022 120.815 37.6022 131.199Z"
      fill="#E25A38"
    />
  </svg>
)

export default React.memo(Logo)

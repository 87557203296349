import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Box, Center, Button, Image, Stack, Text, Anchor } from '@myxplor/stardust'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'
import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import MergeEmails from '~/assets/images/merge_email.gif'
import { redirect } from '~/utils'

type Props = RouteComponentProps

const UpgradePrompt = (_props: Props) => {
  const { token: authToken } = useContext(AuthContext)
  const [sessionDetails] = useContext(SessionContext)

  const onUpdateAccount = () => {
    navigate('/manual-upgrade/phone')
  }

  const onSkip = () => {
    redirect(sessionDetails, authToken)
  }

  return (
    <Container>
      <Header icon="back" onBack={() => navigate('/')}>
        Please update your account information
      </Header>
      <Content>
        <Stack space="small">
          <Box width={1} height={200}>
            <Center>
              <Image src={MergeEmails} width={200} />
            </Center>
          </Box>
          <Text color="higherEmphasis" prefab="body">
            To help us improve how you log in to Xplor, we’re asking our customers to update their details.{' '}
            <Anchor color="primary" prefab="body" href="#">
              Learn more here
            </Anchor>
            .
          </Text>
        </Stack>
      </Content>
      <Footer>
        <Stack space="small">
          <Button fill size="large" onClick={onUpdateAccount}>
            Update Account
          </Button>
          <Button fill size="large" variant="text" onClick={onSkip}>
            Remind Me Later
          </Button>
        </Stack>
      </Footer>
    </Container>
  )
}

export default UpgradePrompt

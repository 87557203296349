import React from 'react'

const Notification = () => (
  <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33331 19.125C8.55072 19.8656 9.23022 20.3743 10.0021 20.3743C10.7739 20.3743 11.4534 19.8656 11.6708 19.125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.5V1.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5C13.4518 3.5 16.25 6.29822 16.25 9.75C16.25 15.6217 17.5 16.625 17.5 16.625H2.5C2.5 16.625 3.75 15.0283 3.75 9.75C3.75 6.29822 6.54822 3.5 10 3.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Notification

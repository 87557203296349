import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import * as R from 'ramda'

import useFetch from 'use-http'

import {
  Alert,
  Box,
  Card,
  Center,
  Form,
  Heading,
  Loading,
  Stack,
  Text,
  TextField,
  Modal,
  Inline,
  Button,
} from '@myxplor/stardust'

import { filterNonValidPhoneChar, isEmailValid, isPhoneValid } from '~/utils'
import MobileIcon from '~/icons/Mobile'

import QueryXplorIDsResult from './QueryXplorIDsResult'
import { getErrorMessage } from '~/api'

type Props = RouteComponentProps

const QueryXplorIDs = (_props: Props) => {
  const { request, data, loading, error } = useFetch()
  const [query, setQuery] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [email, setEmail] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState('')

  const errorMessage = getErrorMessage(error)
  const validPhoneNumber = isPhoneValid(newPhoneNumber)

  const xplorIDs: Stargate.XplorID[] | undefined = R.path(['data', 'xplor_ids'], data)

  const fetchData = async () => {
    const identifier_type = isEmailValid(query) ? 'email' : 'phone'
    const encodedQuery = encodeURIComponent(query)

    request.get(
      `/api/v1/admin/xplor_id?identifier=${encodedQuery}&identifier_type=${identifier_type}&include_all=true`
    )
  }

  const onFormSubmit = async () => fetchData()

  const onSendPINResetEmail = async (identifier: string) => {
    request.post('/api/v1/send-password-reset', {
      user: identifier,
      reset_type: 'phone',
    })
  }

  const onSendPasswordResetEmail = (identifier: string) => {
    request.post('/api/v1/send-password-reset', {
      user: identifier,
      reset_type: 'email',
    })
  }

  const onSendMagicLink = async (xplorID: number, strategyType: string, strategyID: string) => {
    const resp = await request.post('/api/v1/admin/magic-link', {
      user_id: xplorID,
      strategy_type: strategyType,
      strategy_id: strategyID,
    })

    if (resp) fetchData()
  }

  const onUpdatePhoneNumber = (email: string) => {
    setEmail(email)
    setNewPhoneNumber('')
    setShowConfirmation(true)
  }

  const onCancel = () => {
    setEmail('')
    setNewPhoneNumber('')
    setShowConfirmation(false)
  }

  const onUpdateConfirm = () => {
    if (validPhoneNumber) {
      request
        .post('/api/v1/credentials/update-phone-number', {
          email: email,
          phone: newPhoneNumber,
        })
        .then(({ data }) => {
          if (data) {
            window.alert('Success updating phone number')
          }
        })
    }

    onCancel()
  }

  const onUnlinkAccount = async (xplorID: number, account: Stargate.LinkedAccount) => {
    const body = {
      xplor_id: xplorID,
      origin: account.strategy.origin,
      tenant: account.strategy.tenant,
      role: account.strategy.role,
      id: account.strategy.id,
    }

    const resp = await request.delete('/api/v1/admin/contexts', body)

    if (resp) fetchData()
  }

  return (
    <Center y={false}>
      <Box maxWidth={900} width={1}>
        <Stack space="small">
          <Card>
            <Box padding="large">
              <Stack space="small">
                <Heading level={4}>Query Xplor IDs</Heading>

                <Text color="highestEmphasis">
                  Enter an email or phone credential to search for an Xplor ID and linked profiles:
                </Text>

                <Box paddingTop="small" width={1}>
                  <Form onSubmit={onFormSubmit}>
                    <TextField
                      autoComplete="off"
                      label="Phone or Email"
                      name="search"
                      onChange={(value: string) => setQuery(value)}
                    />
                  </Form>
                </Box>
              </Stack>
            </Box>
          </Card>

          {loading ? (
            <Loading size="large" />
          ) : errorMessage ? (
            <Alert tone="negative">{errorMessage}</Alert>
          ) : (
            <QueryXplorIDsResult
              xplorIDs={xplorIDs}
              onSendPINResetEmail={onSendPINResetEmail}
              onSendPasswordResetEmail={onSendPasswordResetEmail}
              onUnlinkAccount={onUnlinkAccount}
              onUpdatePhoneNumber={onUpdatePhoneNumber}
              onSendMagicLink={onSendMagicLink}
            />
          )}
        </Stack>
      </Box>

      <Modal isOpen={showConfirmation} onBlur={onCancel}>
        <Card>
          <Box padding={['medium', 'large']}>
            <Stack space="medium">
              <Text color="higherEmphasis" lineHeight={1} prefab="body">
                Are you sure you want to update this phone number associated to this account?
              </Text>
              <TextField
                label="New Phone Number"
                name="new_phone_credential"
                minLength={6}
                value={newPhoneNumber}
                trailingIcon={
                  <Box color="primary" height={24} width={24}>
                    <MobileIcon />
                  </Box>
                }
                onChange={(value: string) => setNewPhoneNumber(filterNonValidPhoneChar(value))}
              />

              <Inline align="right" space="xxsmall">
                <Button variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <Button onClick={onUpdateConfirm} disabled={!newPhoneNumber}>
                  Confirm
                </Button>
              </Inline>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </Center>
  )
}

export default QueryXplorIDs

import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Box, Stack, Text } from '@myxplor/stardust'

import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'

import MobileInfoIcon from '~/icons/MobileInfo'

type Props = RouteComponentProps

const ForgotPhoneNumber = (_props: Props) => {
  const onBack = () => history.back()
  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Forgot Phone Number
      </Header>

      <Content>
        <Stack align="center" space="large">
          <Box height={73} width={73}>
            <MobileInfoIcon />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            Please contact your child care centre administrator to find your accounts phone number.
          </Text>
        </Stack>
      </Content>

      <Footer />
    </Container>
  )
}

export default ForgotPhoneNumber

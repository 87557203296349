import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import AdminLayout from '~/layouts/AdminLayout'
import QueryXplorIDs from './QueryXplorIDs'
import QueryQKMigrations from './QueryQKMigrations'
import MergeXplorIds from './MergeXplorIds'
import BulkUpgrade from './BulkUpgrade'
import Impersonation from './Impersonation'

type Props = RouteComponentProps

const Admin = (_props: Props) => {
  return (
    <AdminLayout>
      <Router>
        <QueryXplorIDs path="/xplor-id" />
        <QueryQKMigrations path="/qk-migrations" />
        <MergeXplorIds path="/merge-xplor-ids" />
        <BulkUpgrade path="/bulk-upgrade" />
        <Impersonation path="/impersonation" />
      </Router>
    </AdminLayout>
  )
}

export default Admin

import React from 'react'

import { Anchor, Box, Color, Column, Columns, Inline, Stack, Text } from '@myxplor/stardust'

const roleColors = {
  Educator: 'secondary',
  Parent: 'primary',
  Guardian: 'primary',
  Xplorer: 'primary',
} as Record<string, Color>

interface Props {
  account: Stargate.LinkedAccount
  xplorID: number
  onUnlinkAccount(xplorID: number, account: Stargate.LinkedAccount): void
}

const XplorLinkedAccount = ({ account, xplorID, onUnlinkAccount }: Props) => {
  const onConfirmUnlink = () => {
    const message = 'Are you sure you want to unlink this profile? This action cannot be undone.'
    if (confirm(message)) {
      onUnlinkAccount(xplorID, account)
    }
  }

  const toUserRef = ({ origin, tenant, role, id }: Stargate.StrategyReference) => {
    if (tenant) {
      return `${origin}:${tenant}/${role}/${id}`
    } else {
      return `${origin}/${role}/${id}`
    }
  }

  return (
    <Box paddingTop="small" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }} width={1}>
      <Columns alignY="center" space="small">
        <Column>
          <Box
            backgroundColor={roleColors[account.strategy.role] || 'lowEmphasis'}
            borderRadius="circular"
            height={32}
            width={32}
          />
        </Column>

        <Column width="fill">
          <Box width={1}>
            <Stack space="xxxsmall">
              <Text prefab="overline">{account.strategy.role}</Text>
              <Text color="highestEmphasis" weight="bold">
                {`${account.first_name} ${account.last_name}`}
              </Text>
              <Inline space="xxsmall">
                <Text color="highestEmphasis">{account.email}</Text>
                {!!account.email && !!account.phone && <Text color="highestEmphasis">&bull;</Text>}
                <Text color="highestEmphasis">{account.phone}</Text>
                {account.is_migrated && (
                  <Text italic color="orange900" weight="bold">
                    Migrated User: Once unlinking this profile, it cannot be re-linked.
                  </Text>
                )}
              </Inline>
              <Text color="highestEmphasis" weight="bold">
                {toUserRef(account.strategy)}
              </Text>
              <Text prefab="caption">{account.services.join(', ')}</Text>
            </Stack>
          </Box>
        </Column>

        <Column align="right">
          <Anchor onClick={onConfirmUnlink}>Unlink Profile</Anchor>
        </Column>
      </Columns>
    </Box>
  )
}

XplorLinkedAccount.displayName = 'XplorLinkedAccount'
export default XplorLinkedAccount

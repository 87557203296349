import React, { useContext } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Button, Stack, Text, TextField } from '@myxplor/stardust'

import { SessionContext } from '~/contexts/SessionContext'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'
import CenteredContentLayout from '~/layouts/CenteredContentLayout'

type Props = RouteComponentProps

const NoContexts = (_props: Props) => {
  const [{ identifier }] = useContext(SessionContext)

  const onBack = () => {
    history.replaceState(null, '', '/')
    navigate('/')
  }

  const onClick = () => {
    history.replaceState(null, '', '/')

    window.location.assign(
      'https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=myxplor.zendesk.com'
    )
  }

  return (
    <CenteredContentLayout>
      <Container>
        <Header icon="back" onBack={onBack}>
          Contact support
        </Header>
        <Content>
          <Stack align="center" space="large">
            <Text color="higherEmphasis" prefab="body">
              We have recognised a problem with your account. Please contact support to recover your account.
            </Text>
            <TextField label="Email" name="email" value={identifier || ''} readOnly disabled />
            <TextField label="Email" name="error" value={'XID-403'} readOnly disabled />
          </Stack>
        </Content>
        <Footer>
          <Button fill variant={'outline'} size="large" onClick={onClick}>
            Contact support
          </Button>
        </Footer>
      </Container>
    </CenteredContentLayout>
  )
}

export default NoContexts

import React, { useContext, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import {
  Alert,
  Anchor,
  Box,
  Button,
  Card,
  Column,
  Columns,
  Form,
  Heading,
  Inline,
  Image,
  Modal,
  PageHeading,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import { getErrorMessage, getErrorStatus } from '~/api'
import HeroImageLayout from '~/layouts/HeroImageLayout'
import splash from '~/assets/images/login_splash.png'
import homeLogo from '~/assets/images/home_16px.png'
import playgroundLogo from '~/assets/images/playground_16px.png'
import VisibilityOffIcon from '~/icons/VisibilityOff'
import VisibilityOnIcon from '~/icons/VisibilityOn'
import { redirect } from '~/utils'

const HOME_STRATEGIES = ['Parent', 'Xplorer', 'Discover/Guardian', 'QK/Guardian']

const PLAYGROUND_STRATEGIES = [
  'SuperAdmin',
  'GroupAdmin',
  'ProviderAdmin',
  'ServiceAdmin',
  'Educator',
  'Discover/Educator',
  'QK/Educator',
]

const isUpgradable = (linked: boolean, strategyType: string) => {
  return !linked && !!strategyType && ['Educator', 'Parent', 'Xplorer'].includes(strategyType)
}

const Login = (_props: RouteComponentProps) => {
  const referrer =
    new URLSearchParams(window.location.search).get('app') || ('stargate-client-web' as Stargate.Referrer)

  const { setAuthContext } = useContext(AuthContext)
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)

  const [identifier, setIdentifier] = useState(new URLSearchParams(window.location.search).get('email') || '')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showError, setShowError] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const { request, loading, response, error } = useFetch({ credentials: 'include' })

  const errorMessage = getErrorMessage(error)
  const errorStatus = getErrorStatus(error)

  const onSubmit = async () => {
    if (!identifier || !password) return

    setShowError(false)

    // Refresh cookie is based on auth/first result, so getting it right from the start
    // prevents additional context lookups later on
    const strategies =
      referrer === 'home-web'
        ? [...HOME_STRATEGIES, ...PLAYGROUND_STRATEGIES]
        : [...PLAYGROUND_STRATEGIES, ...HOME_STRATEGIES]

    await request.post('/api/v1/auth/first', {
      identifier: identifier.trim(),
      password: password.trim(),
      strategies,
      upgrade_skip: true,
      app_referrer: referrer,
    })

    if (response.status === 200) {
      const data = response.data.data
      setAuthContext(data.token)

      const newSessionDetails = {
        ...sessionDetails,
        referrer: referrer as Nullable<Stargate.Referrer>,
        identifier: identifier,
        linked: data.linked as Nullable<boolean>,
        strategyType: data.strategy_type as Nullable<Stargate.StrategyType>,
        strategyID: data.strategy_id as Nullable<number>,
      }

      setSessionDetails(newSessionDetails)

      isUpgradable(data.linked, data.strategy_type)
        ? navigate('/manual-upgrade')
        : redirect(newSessionDetails, data.token, referrer as Nullable<Stargate.Referrer>)
    } else if (response.status === 403) {
      setSessionDetails({ ...sessionDetails, identifier })
      navigate('/no-services')
    } else {
      setShowError(true)
    }
  }

  const onTogglePasswordMask = () => {
    setShowPassword(!showPassword)
  }

  const onForgotPassword = () => {
    navigate('/account-recovery/reset-password')
  }

  const onForgotPIN = () => {
    navigate('/account-recovery/reset-pin')
  }

  const onSSOLogin = () => {
    const query_param = referrer ? `?app=${referrer}` : ''
    navigate('/sso' + query_param)
  }

  const onToggleCommentMask = () => {
    setShowError(false)
    setShowModal(!showModal)
  }

  return (
    <Box>
      <HeroImageLayout image={splash}>
        <Form onSubmit={onSubmit}>
          <Stack align="center" space="large">
            <PageHeading overline="Playground & Home">Login</PageHeading>

            <Box paddingBottom={['xxsmall', 'large']} paddingTop={['xxsmall', 'xlarge']}>
              <Columns space="xsmall">
                <Column>
                  <Image src={playgroundLogo} width={92} height={92} />
                </Column>
                <Column>
                  <Image src={homeLogo} width={92} height={92} />
                </Column>
              </Columns>
            </Box>

            <Stack space="small">
              <TextField
                color="secondary"
                label="Email or Phone"
                name="identifier"
                value={identifier}
                type="text"
                onChange={(value: string) => {
                  setShowError(false)
                  setIdentifier(value)
                }}
              />
              <TextField
                color="secondary"
                label="Password or PIN"
                name="password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(value: string) => {
                  setShowError(false)
                  setPassword(value)
                }}
                trailingIcon={
                  <Box height={24} width={24} color="secondary" onClick={onTogglePasswordMask}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  </Box>
                }
              />
            </Stack>

            {errorMessage && errorStatus !== 422 && showError && (
              <Alert tone="negative">{errorMessage}</Alert>
            )}

            <Button
              color="secondary"
              disabled={!identifier || !password || loading}
              fill
              size="large"
              type="submit">
              Login
            </Button>
            <Anchor onClick={onForgotPassword} color="secondary">
              Forgot password?
            </Anchor>
            <Anchor onClick={onForgotPIN} color="secondary">
              Forgot pin?
            </Anchor>
            <Anchor onClick={onSSOLogin} color="secondary">
              Login with SSO
            </Anchor>

            <Box
              position={['relative', 'absolute']}
              width={1}
              paddingTop="large"
              paddingBottom={['medium', 'none']}
              textAlign="center">
              <Text prefab="caption">
                {'View '}
                <Anchor
                  color="mediumEmphasis"
                  decorate="under"
                  prefab="caption"
                  href="https://www.xplortechnologies.com/us/terms-use">
                  Terms and Conditions
                </Anchor>
                {' and '}
                <Anchor
                  color="mediumEmphasis"
                  decorate="under"
                  prefab="caption"
                  href="https://www.xplortechnologies.com/us/privacy-notice">
                  Privacy Statements.
                </Anchor>
              </Text>
            </Box>
          </Stack>
        </Form>
      </HeroImageLayout>
      <Modal isOpen={!!errorMessage && errorStatus === 422 && showError} onBlur={onToggleCommentMask}>
        <Card>
          <Box width={[300, 400]} padding={['medium', 'large']}>
            <Stack space="medium">
              <Heading level={6}>Have you already merged accounts?</Heading>
              <Text>{errorMessage}</Text>
              <Box width={1}>
                <Inline align="right">
                  <Button color="primary" variant="outline" onClick={onToggleCommentMask}>
                    Got it!
                  </Button>
                </Inline>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </Box>
  )
}

Login.displayName = 'Login'
export default Login

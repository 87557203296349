import React, { useState } from 'react'

import {
  Box,
  Button,
  Card,
  Column,
  Columns,
  Form,
  Heading,
  Inline,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import { isEmailValid } from '../../utils'
import useFetch from 'use-http'

interface Props {
  messageLog: Stargate.MessageLog
}

const MessageLogCard = ({ messageLog }: Props) => {
  const { resolved_at, resolved_by, resolved_reason, status } = messageLog
  const err = messageLog.errors || ''
  const errDetails = err.details
  const errMsg = err ? err.code : ''
  const [forcedCreateEmail, setForcedCreateEmail] = useState('')
  const [resolveReason, setResolveReason] = useState('')
  const { request } = useFetch()

  const validEmail = isEmailValid(forcedCreateEmail)

  const messageStatus = (msgStatus: string) => {
    if (msgStatus === 'failed') {
      return (
        <Text color="orange900" weight="semiBold" case="upper">
          {msgStatus}
        </Text>
      )
    }

    if (msgStatus === 'processed') {
      return (
        <Text color="cyan500" weight="semiBold" case="upper">
          {msgStatus}
        </Text>
      )
    }
  }

  const humanize = (str: string) => {
    return str.replace(/_/g, ' ')
  }

  const onForceMerge = () => {
    if (resolveReason && validEmail) {
      const body = { reason: resolveReason, email: forcedCreateEmail, message_log_id: messageLog.id }
      request.post('/api/v1/admin/message-logs/force-create', body).then(({ data }) => {
        if (data) {
          window.alert('Success')
        }
      })
    }
  }

  const onResolveMultiUserErr = (user_id: number) => {
    if (!resolveReason) {
      window.alert('Resolve reason cannot be blank.')
    } else {
      const body = { reason: resolveReason, message_log_id: messageLog.id, user_id: user_id }
      request.post('/api/v1/admin/message-logs/resolve-multiple-user-error', body).then(({ data }) => {
        if (data) {
          window.alert('Success')
        }
      })
    }
  }

  return (
    <Card>
      <Box padding="large">
        <Stack>
          <Heading level={6}>{messageLog.body.email}</Heading>
          <Box>&nbsp;</Box>

          {err && status != 'processed' && (
            <TextField
              label="Resolve Reason"
              name="resolve_reason"
              value={resolveReason}
              onChange={(value: string) => setResolveReason(value)}
              required
            />
          )}
        </Stack>

        <Box>&nbsp;</Box>

        <Stack>
          <Box paddingTop="small" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }} width={1}>
            <Stack space="xsmall">
              {err ? (
                <Inline>
                  <Text>{messageStatus(status)}</Text>
                  <Text case="title" color="orange900" weight="semiBold">
                    : {humanize(err.code)}
                  </Text>
                </Inline>
              ) : (
                <Text case="title">{messageStatus(status)}</Text>
              )}

              {status === 'processed' && (
                <Stack space="xxsmall">
                  {resolved_by && <Text>Resolved by: {resolved_by}</Text>}
                  {resolved_at && <Text>Resolved at: {resolved_at}</Text>}
                  {resolved_reason && <Text>Resolved reason: {resolved_reason}</Text>}
                </Stack>
              )}
            </Stack>
          </Box>
          {status === 'failed' && (
            <Box width={1}>
              {errMsg === 'multiple_universal_users' && (
                <div>
                  <Box>&nbsp;</Box>
                  <Stack space="xxsmall">
                    <Text color="highestEmphasis" prefab="body" weight="medium">
                      {"Conflicting Xplor ID's"}
                    </Text>
                    {errDetails.map(errDetail => (
                      <Stack space="xsmall" key={errDetail.user_id}>
                        <Columns alignY="center">
                          <Column>
                            <Text>{errDetail.identifier}</Text>
                          </Column>
                          <Column>
                            <Stack align="right">
                              <Button type="submit" onClick={() => onResolveMultiUserErr(errDetail.user_id)}>
                                Merge
                              </Button>
                            </Stack>
                          </Column>
                        </Columns>
                      </Stack>
                    ))}
                  </Stack>
                </div>
              )}
              <Box>&nbsp;</Box>
              <Box paddingTop="small" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }} width={1}>
                <Form onSubmit={onForceMerge}>
                  <Stack space="xsmall">
                    <Text color="highestEmphasis" prefab="body" weight="medium">
                      Force Create
                    </Text>

                    <Columns alignY="center">
                      <Column>
                        <TextField
                          label="Enter email address"
                          name="forcedMergeEmail"
                          value={forcedCreateEmail}
                          onChange={(value: string) => setForcedCreateEmail(value)}
                          required
                        />
                      </Column>

                      <Column>
                        <Stack align="right">
                          <Button type="submit" color="primary">
                            Create
                          </Button>
                        </Stack>
                      </Column>
                    </Columns>
                  </Stack>
                </Form>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </Card>
  )
}

MessageLogCard.displayName = 'MessageLogCard'
export default MessageLogCard

export interface AvatarSizeProps {
  large?: boolean
  small?: boolean
  xsmall?: boolean
  xxsmall?: boolean
}
export const large = (props: AvatarSizeProps): { height: string; width: string } | null =>
  props.large ? { height: '120px', width: '120px' } : null
export const small = (props: AvatarSizeProps): { height: string; width: string } | null =>
  props.small ? { height: '60px', width: '60px' } : null
export const xsmall = (props: AvatarSizeProps): { height: string; width: string } | null =>
  props.xsmall ? { height: '40px', width: '40px' } : null
export const xxsmall = (props: AvatarSizeProps): { height: string; width: string } | null =>
  props.xxsmall ? { height: '24px', width: '24px' } : null

import React from 'react'

const Email = () => (
  <svg viewBox="0 0 96 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M89.4924 70.8625H6.4968C4.43187 70.8398 2.75687 69.1839 2.71059 67.1193V6.88403C2.73384 4.80267 4.41544 3.12107 6.4968 3.09781H89.4924C91.5643 3.14336 93.2331 4.81212 93.2787 6.88403V67.1193C93.2554 69.2007 91.5738 70.8823 89.4924 70.9055"
      fill="#F7E1C6"
    />
    <path
      d="M93.2787 6.88403L50.2534 42.638C48.8348 43.7821 46.8103 43.7821 45.3916 42.638L2.71059 6.88403V16.5217L40.7449 48.2313C44.9432 51.7345 51.046 51.7345 55.2444 48.2313L93.2787 16.5217V6.88403Z"
      fill="#DFCCB4"
    />
    <path
      d="M89.4924 2.92572H6.49681C5.46595 2.93672 4.48307 3.3629 3.77048 4.10789C3.05788 4.85287 2.67578 5.85371 2.71059 6.88404L45.7358 42.638C47.1655 43.7505 49.168 43.7505 50.5976 42.638L93.2787 6.88404C93.3017 5.85698 92.9157 4.86284 92.2056 4.12045C91.4955 3.37807 90.5195 2.94829 89.4924 2.92572Z"
      fill="#F8EADB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49681 4.51855e-07H89.4924C91.1773 -0.000640305 92.7908 0.680217 93.9658 1.88768C95.1409 3.09515 95.7776 4.72655 95.7311 6.41076V66.646C95.7541 68.3155 95.107 69.9245 93.9345 71.1132C92.7621 72.3018 91.162 72.9709 89.4924 72.9708H6.41075C4.90241 72.9844 3.44383 72.4317 2.32336 71.4219C0.940314 70.1569 0.184814 68.3468 0.25815 66.474V6.23866C0.25815 2.79314 3.05129 4.51855e-07 6.49681 4.51855e-07ZM89.4924 4.90487H6.49681C6.0787 4.87617 5.66926 5.03364 5.37815 5.33513L47.1556 40.0565C47.6314 40.4887 48.3578 40.4887 48.8336 40.0565L84.0282 10.8424C84.6953 10.2108 85.6593 10.0049 86.5261 10.3089C87.393 10.6129 88.0172 11.3759 88.1436 12.2858C88.2699 13.1956 87.8772 14.0998 87.1261 14.6286L52.1466 43.8427C50.9839 44.7343 49.5671 45.2317 48.1022 45.2625C46.6347 45.2367 45.2212 44.7047 44.1008 43.7566L5.37815 11.4877V65.3123L26.0303 49.9092C27.117 49.1112 28.6412 49.3208 29.4723 50.3825C30.2752 51.483 30.0663 53.0211 28.999 53.8676L10.0679 68.0229H86.2655L67.2484 53.8676C66.1597 53.0333 65.9484 51.4769 66.7751 50.3825C67.6062 49.3208 69.1303 49.1112 70.2171 49.9092L90.7832 65.0971V6.23866C90.7949 5.88893 90.664 5.54946 90.4207 5.29801C90.1774 5.04656 89.8424 4.90468 89.4924 4.90487Z"
      fill="#464C55"
    />
  </svg>
)

export default React.memo(Email)

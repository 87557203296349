import React, { ReactNode, useState } from 'react'

type ContextProps = Session

const initValue: ContextProps = {
  referrer: null,
  strategyID: null,
  strategyType: null,
  firstName: null,
  pin: null,
  email: null,
  password: null,
  phone: null,
  identifier: null,
  linked: null,
  serviceNames: null,
  ssoOnly: null,
  redirect: null,
  external: null,
  setPassword: null,
}

type SessionContextProps = [ContextProps, (context: ContextProps) => void]

export const SessionContext = React.createContext<SessionContextProps>([
  initValue,
  (_context: ContextProps) => null,
])

interface Props {
  children: ReactNode
}

export const SessionProvider = ({ children }: Props) => {
  const [sessionDetails, setSessionDetails] = useState<ContextProps>(initValue)

  return (
    <SessionContext.Provider value={[sessionDetails, setSessionDetails]}>{children}</SessionContext.Provider>
  )
}

import config from '~/config'

export interface StardorStrategy {
  display_name: string
  id: number
  strategy: string
  email?: string
  services?: [
    {
      name: string
    }
  ]
}

/**
 *
 * @param strategy The strategy the user has selected to be authenticated against current access.
 * @param authToken The authentication token from Stardor, only needed for non-SSO logins.
 * SSO logins will read the pre-token from the cookie and use that for a different authentication
 * method
 * @returns Promise<Response> of the auth call to Stardor.
 */
export const authWithStrategy = async (strategy: StardorStrategy, authToken?: string) => {
  try {
    /**
     * For when a final token has been issued, we only need to use authorise the context
     * against the token.
     */
    if (authToken) {
      return fetch(`${config.STARGATE_API}/api/v1/auth/context`, {
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          user_ref: `${strategy.strategy}/${strategy.id}`,
        }),
        credentials: 'include',
        method: 'POST',
      })
    } else {
      /**
       * There is no need to fetch and explicitly attach cookie here
       * It is supposed to be auto picked up by the request to the domain
       * that this cookie is for.
       * NOTE: I have observed in Chrome a problem with cookie setting
       * by the Stardor for an auth.myxplor.com domain stating in the
       * dev panel that this cookie is for the wrong domain. Causing fault
       * on the below call due to cookie being missing. It works in Safari.
       * TODO: Find out more and address the issue in the Stardor with
       * cookie setting (use root domain?)
       */
      return fetch(`${config.STARGATE_API}/api/v1/auth/admin-context`, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          user: `${strategy.strategy}/${strategy.id}`,
        }),
        credentials: 'include',
        method: 'POST',
      })
    }
  } catch (e) {
    console.log('Unauthorised, token absent.')
    return null
  }
}

import React from 'react'

import { Text, useColor } from '@myxplor/stardust'
import zxcvbn, { ZXCVBNResult } from 'zxcvbn'
import styled from 'styled-components'

const createPasswordLabel = (password: ZXCVBNResult) => {
  switch (password.score) {
    case 2:
      return 'Fair'
    case 3:
      return 'Good'
    case 4:
      return 'Strong'
    default:
      return 'Weak'
  }
}

interface StrengthProps {
  weak: string
  fair: string
  good: string
  strong: string
}

const PasswordStrengthBox = styled.div`
  text-align: left;
  width: 100%;

  progress {
    border-radius: 6px;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    margin-top: 4px;
  }

  progress::-webkit-progress-value {
    border-radius: 6px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  progress::-webkit-progress-bar {
    background-color: #d3d3d3;
    border-radius: 6px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  progress::-moz-progress-bar {
    border-radius: 6px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .strength-Weak {
    color: ${(props: StrengthProps) => props.weak};
  }
  .strength-Fair {
    color: ${(props: StrengthProps) => props.fair};
  }
  .strength-Good {
    color: ${(props: StrengthProps) => props.good};
  }
  .strength-Strong {
    color: ${(props: StrengthProps) => props.strong};
  }

  .strength-Weak::-webkit-progress-value {
    background-color: ${(props: StrengthProps) => props.weak};
  }
  .strength-Fair::-webkit-progress-value {
    background-color: ${(props: StrengthProps) => props.fair};
  }
  .strength-Good::-webkit-progress-value {
    background-color: ${(props: StrengthProps) => props.good};
  }
  .strength-Strong::-webkit-progress-value {
    background-color: ${(props: StrengthProps) => props.strong};
  }

  .strength-Weak::-moz-progress-bar {
    background-color: ${(props: StrengthProps) => props.weak};
  }
  .strength-Fair::-moz-progress-bar {
    background-color: ${(props: StrengthProps) => props.fair};
  }
  .strength-Good::-moz-progress-bar {
    background-color: ${(props: StrengthProps) => props.good};
  }
  .strength-Strong::-moz-progress-bar {
    background-color: ${(props: StrengthProps) => props.strong};
  }
`

interface Props {
  password: string
}

const PasswordStrengthMeter = ({ password }: Props) => {
  const passwordStrength = zxcvbn(password)
  const strengthClassName = `strength-${createPasswordLabel(passwordStrength)}`
  const minPasswordLength = 8

  const weakStrength = useColor('negative')
  const fairStrength = useColor('orange500')
  const goodStrength = useColor('cyan400')
  const strongStrength = useColor('cyan700')

  let passwordScore
  if (password.length == 0) {
    passwordScore = 0
  } else if (passwordStrength.score == 0) {
    passwordScore = 1
  } else {
    passwordScore = passwordStrength.score
  }

  let passwordLabel = ''
  if (password && password.length < minPasswordLength) {
    passwordLabel = 'Strength: Too short'
  } else if (password) {
    passwordLabel = `Strength: ${createPasswordLabel(passwordStrength)}`
  }

  return (
    <PasswordStrengthBox weak={weakStrength} fair={fairStrength} good={goodStrength} strong={strongStrength}>
      <Text prefab="caption">{passwordLabel}&nbsp;</Text>

      <progress className={strengthClassName} value={passwordScore} max="4" />
    </PasswordStrengthBox>
  )
}

export default PasswordStrengthMeter

import React from 'react'
import { Router, Redirect, RouteComponentProps } from '@reach/router'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'

import ResetPassword from './ResetPassword'
import ResetPIN from './ResetPIN'
import CheckInbox from './CheckInbox'
import CreatePassword from './CreatePassword'
import CreatePIN from './CreatePIN'
import AllDone from './AllDone'
import ForgotEmail from './ForgotEmail'
import ForgotPhoneNumber from './ForgotPhoneNumber'

type Props = RouteComponentProps

const AccountRecovery = (_props: Props) => {
  return (
    <CenteredContentLayout>
      <Router>
        <AllDone path="/all-done" />
        <CheckInbox path="/check-inbox" />
        <CreatePassword path="/create-password" />
        <CreatePIN path="/create-pin" />
        <ForgotEmail path="/forgot-email" />
        <ForgotPhoneNumber path="/forgot-phone-number" />
        <ResetPassword path="/reset-password" />
        <ResetPIN path="/reset-pin" />
        <Redirect from="*" to="/" default noThrow />
      </Router>
    </CenteredContentLayout>
  )
}

export default AccountRecovery

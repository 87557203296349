import React from 'react'

const Key = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <mask id="a" width="24" height="14" x="0" y="5" maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16 19h6v-4h2V9H13.32C12.17 6.58 9.72 5 7 5c-3.86 0-7 3.14-7 7s3.14 7 7 7c2.72 0 5.18-1.58 6.32-4H16v4zm4-2h-2v-4h-6.06l-.23.67C11.01 15.66 9.10999 17 6.99999 17c-2.76 0-5-2.24-5-5 0-2.76001 2.24-5.00001 5-5.00001 2.11 0 4.01001 1.34 4.71001 3.33001l.23.67H22v2h-2v4zM7.00001 15c-1.65 0-3-1.35-3-3s1.35-2.99999 3-2.99999S10 10.35 10 12s-1.34999 3-2.99999 3zM6 12c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="#000" fillOpacity=".54" d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default React.memo(Key)

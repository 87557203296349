import React, { useState } from 'react'

import {
  Anchor,
  Box,
  Card,
  Column,
  Columns,
  Heading,
  Stack,
  Text,
  TextField,
  Inline,
  SelectField,
} from '@myxplor/stardust'

import XplorLinkedAccount from './XplorLinkedAccount'
import { isNull } from 'util'

interface Props {
  user: Stargate.XplorID
  onSendPINResetEmail(identifier: string): void
  onSendPasswordResetEmail(identifier: string): void
  onUnlinkAccount(xplorID: number, account: Stargate.LinkedAccount): void
  onUpdatePhoneNumber(email: string, phoneNumber: string): void
  onSendMagicLink(xplorID: number, strategyType: string, strategyID: string): void
}

const XplorIDCard = ({
  user,
  onSendPINResetEmail,
  onSendPasswordResetEmail,
  onUnlinkAccount,
  onUpdatePhoneNumber,
  onSendMagicLink,
}: Props) => {
  const [strategyType, setStrategyType] = useState('Parent')
  const [strategyID, setStrategyID] = useState('')

  const findIdentifier = (identifierType: string) => {
    const result = user.identifiers.find(identifier => identifier.identifier_type === identifierType)
    return result ? result.identifier : ' '
  }

  const isDeletedByIdentifier = (identifierType: string) => {
    const result = user.identifiers.find(identifier => identifier.identifier_type === identifierType)
    return result ? !isNull(result.deleted_at) : ''
  }

  const emailIdentifier = findIdentifier('email')
  const phoneIdentifier = findIdentifier('phone')

  const isEmailDelete = isDeletedByIdentifier('email')

  const onMagicLinkSubmit = () => {
    onSendMagicLink(user.xplor_id, strategyType, strategyID)
  }

  return (
    <Card>
      <Box padding="large">
        <Stack space="small">
          <Columns alignY="center">
            <Column width="fill">
              <Stack space="xxxsmall">
                <Inline alignY="center">
                  <Heading level={6}>{emailIdentifier}</Heading>
                  {isEmailDelete && (
                    <Text italic color="orange900">
                      (Deleted)
                    </Text>
                  )}
                </Inline>

                <Inline alignY="center">
                  <Text color="highestEmphasis" prefab="body">
                    {phoneIdentifier}
                  </Text>
                </Inline>
              </Stack>
            </Column>
            <Column>
              <Stack align="right" space="xxsmall">
                <Anchor onClick={() => onSendPasswordResetEmail(emailIdentifier)}>Reset Password</Anchor>
                <Anchor onClick={() => onSendPINResetEmail(emailIdentifier)}>Reset Access Code</Anchor>
                <Anchor onClick={() => onUpdatePhoneNumber(emailIdentifier, phoneIdentifier)}>
                  Update Phone Number
                </Anchor>
              </Stack>
            </Column>
          </Columns>

          <Box width={1} paddingTop="small" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <Columns alignY="center">
              <Box width="200px" paddingRight="small">
                <SelectField
                  size="large"
                  items={['Parent', 'Educator', 'Xplorer']}
                  label={'Select Profile type'}
                  required={true}
                  onSelect={setStrategyType}
                  defaultValue={'Parent'}
                />
              </Box>
              <Box width="200px">
                <TextField
                  autoComplete="off"
                  label="Profile ID"
                  name="email"
                  required={true}
                  onChange={(value: string) => setStrategyID(value)}
                />
              </Box>
              <Column alignY="center">
                <Stack align="right">
                  <Anchor onClick={onMagicLinkSubmit}>Send Link Profile Email</Anchor>
                </Stack>
              </Column>
            </Columns>
          </Box>

          <Stack space="small">
            {user.linked_accounts.map(account => (
              <XplorLinkedAccount
                key={account.strategy.id}
                account={account}
                xplorID={user.xplor_id}
                onUnlinkAccount={onUnlinkAccount}
              />
            ))}
          </Stack>
        </Stack>
      </Box>
    </Card>
  )
}

XplorIDCard.displayName = 'XplorIDCard'
export default XplorIDCard

import React, { useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Box, Form, Button, Stack, Text, TextField } from '@myxplor/stardust'

import { getErrorMessage } from '~/api'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import isValidPIN from '~/utils/isValidPIN'
import { PIN_LENGTH } from '~/helpers/constants'

import KeyIcon from '~/icons/Key'

type Props = RouteComponentProps

const CreatePIN = (_props: Props) => {
  const { error, request } = useFetch()
  const [pinInvalidMessage, setPINInvalidMessage] = useState<Nullable<string>>(null)
  const [pin, setPIN] = useState('')
  const [repeatPIN, setRepeatPIN] = useState('')
  const resetToken = new URLSearchParams(window.location.search).get('token')
  const body = { reset_token: resetToken, new_password: pin, reset_type: 'phone' }
  const [showError, setShowError] = useState(true)
  const errorMessage = getErrorMessage(error)

  const onBackToStart = () => {
    navigate('/')
  }

  const onSubmit = () => {
    setPINInvalidMessage(null)

    if (!isValidPIN(pin)) {
      return setPINInvalidMessage(`PINs must be ${PIN_LENGTH} numbers`)
    }
    if (pin !== repeatPIN) {
      return setPINInvalidMessage('PINs do not match')
    }

    // explicit history modification to redirect to '/' when browser back button pressed
    history.replaceState(null, '', '/')
    request.post('/api/v1/set-new-password', body).then(({ data }) => {
      if (!data) {
        setShowError(true)
      } else {
        navigate('/account-recovery/all-done', { state: { resetType: 'phone' } })
      }
    })
  }

  const onBack = () => history.back()

  return (
    <Form onSubmit={error ? onBackToStart : onSubmit}>
      <Container>
        <Header icon="back" onBack={onBack}>
          Create PIN
        </Header>

        <Content>
          <Stack space="small">
            <Text color="higherEmphasis" prefab="body">
              Enter your 4 digit numerical pin
            </Text>

            {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}
            {pinInvalidMessage && <Alert tone="negative">{pinInvalidMessage}</Alert>}

            <TextField
              label="PIN"
              name="pin"
              value={pin}
              pattern="[0-9]{4}"
              maxLength={PIN_LENGTH}
              type="password"
              trailingIcon={
                <Box height={24} width={24} color="primary">
                  <KeyIcon />
                </Box>
              }
              onChange={(value: string) => setPIN(value)}
            />
            <TextField
              label="Repeat PIN"
              name="repeatPIN"
              value={repeatPIN}
              pattern="[0-9]{4}"
              maxLength={PIN_LENGTH}
              type="password"
              trailingIcon={
                <Box height={24} width={24} color="primary">
                  <KeyIcon />
                </Box>
              }
              onChange={(value: string) => setRepeatPIN(value)}
            />
          </Stack>
        </Content>

        <Footer>
          <Button type="submit" disabled={!pin || !repeatPIN} fill size="large">
            {error ? 'Try again' : 'Reset'}
          </Button>
        </Footer>
      </Container>
    </Form>
  )
}

export default CreatePIN

import isNil from 'ramda/src/isNil'
import test from 'ramda/src/test'

import { PIN_LENGTH } from '~/helpers/constants'

const isValidPIN = (code: string): boolean => {
  return !isNil(code) && code.length === PIN_LENGTH && test(/[0-9]{4}/, code)
}

export default isValidPIN

import React, { useContext, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import { generateCustomTokenOptions } from '~/api'

type Props = RouteComponentProps

const AutoLink = (_props: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)
  const token = new URLSearchParams(window.location.search).get('token') as string
  const { request, response } = useFetch({ ...generateCustomTokenOptions(token), credentials: 'include' })

  const autoLink = async () => {
    await request.post('/api/v1/onboarding/auto-link')

    if (response.ok) {
      const data = response.data.data

      if (data.link_success === true) {
        setSessionDetails({
          ...sessionDetails,
          referrer: data.strategy_type === 'Educator' ? 'playground' : 'home-web',
          firstName: data.first_name,
          email: data.email,
          ssoOnly: data.sso_only,
          serviceNames: data.service_names,
          identifier: data.email,
          strategyType: data.strategy_type,
          strategyID: data.strategy_id,
          external: data.external,
          setPassword: data.set_password,
        })
        if (data.set_password === true) {
          navigate('/onboarding/create-account/set-password')
          setAuthContext(token)
        } else {
          navigate('/onboarding/create-account/success')
        }
      } else {
        setSessionDetails({
          ...sessionDetails,
          serviceNames: data.service_names,
          email: data.email,
          ssoOnly: data.sso_only,
          identifier: data.email,
          strategyType: data.strategy_type,
          strategyID: data.strategy_id,
          external: data.external,
          setPassword: data.set_password,
        })
        navigate('/onboarding/welcome')
        setAuthContext(token)
      }
    } else if (response.status === 401) {
      navigate('/onboarding/resend-email')
      setAuthContext(token)
    } else {
      navigate('/error')
    }
  }

  useEffect(() => {
    if (token) {
      autoLink()
    } else {
      navigate('/error')
    }
  }, [])

  return <></>
}

export default AutoLink

/* global process */

// Top-level configuration for the App. Takes most variables from ENV files,
// sourced via dotenv

const testDefault = (actual: string | undefined, test: string) =>
  process.env.NODE_ENV === 'test' ? test : actual

const STARGATE_API = testDefault(process.env.STARGATE_API, 'http://localhost')
const HODOR_API = process.env.HODOR_API
const HOMEWEB_URL = process.env.HOMEWEB_URL || 'ERROR_NO_HOME_WEB_URL_SET'
const PLAYGROUND_URL = process.env.PLAYGROUND_URL || 'ERROR_NO_PLAYGROUND_WEB_URL_SET'
const PRODADMIN_API = process.env.PRODADMIN_API
const GOOGLE_ANALYTICS_PROPERTY = process.env.GOOGLE_ANALYTICS_PROPERTY
const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID
const VERSION = process.env.VERSION

const config = {
  STARGATE_API,
  HODOR_API,
  HOMEWEB_URL,
  PLAYGROUND_URL,
  PRODADMIN_API,
  GOOGLE_ANALYTICS_PROPERTY,
  GOOGLE_TAG_MANAGER_ID,
  VERSION,
}

function validateConfig() {
  Object.entries(config).forEach(([key, value]: [string, Nullable<string>]) => {
    if (value === null || value === undefined) {
      throw Error(
        `App not configured correctly - missing environment variable: ${key}.
        If running locally: Have you configured your .env?`
      )
    }
  })
}

validateConfig()

export default config

import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Animation, Box, Button, Stack, Text, TextField } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'
import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import { SessionContext } from '~/contexts/SessionContext'
import { prettyNamePrint } from '~/utils'
import config from '~/config'

type Props = RouteComponentProps

const Success = (_props: Props) => {
  const [{ strategyID, referrer, firstName, identifier }] = useContext(SessionContext)

  const onClick = () => {
    switch (referrer) {
      case 'playground': {
        window.location.assign(`${config.PLAYGROUND_URL}?strategy_type=Educator&strategy_id=${strategyID}`)
        break
      }
      default: {
        window.location.assign(`${config.HOMEWEB_URL}?strategy_type=Parent&strategy_id=${strategyID}`)
        break
      }
    }
  }

  return (
    <Container>
      <Header icon="close" onBack={onClick}>{`Thanks ${prettyNamePrint(firstName)}`}</Header>
      <Content>
        <Stack align="center" space="large">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            Thanks for taking the time to upgrade your account. For all future logins please continue to use
            the email below.
          </Text>
          <TextField label="Email" name="email" value={identifier || ''} readOnly disabled />
        </Stack>
      </Content>
      <Footer>
        <Button fill size="large" onClick={onClick}>
          Finished
        </Button>
      </Footer>
    </Container>
  )
}

export default Success

import React, { useContext, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Anchor, Animation, Box, Stack, Text, TextField } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'

import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'
import { SessionContext } from '~/contexts/SessionContext'
import { prettyNamePrint } from '~/utils'

type Props = RouteComponentProps

const HubGuestLanding = (_props: Props) => {
  const { request, response } = useFetch()
  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState(null)
  const [sessionContext] = useContext(SessionContext)

  const onBack = () => {
    history.replaceState(null, '', '/')
    navigate('/')
  }

  const fetchDetails = async () => {
    // First name will only be missing when being refirected from the login page,
    // so we need to fetch it. This is a little clunky.
    if (!sessionContext.firstName) {
      await request.get('/api/v1/hubguest/details/')

      if (response.ok) {
        setFirstName(response.data.data.first_name)
      } else {
        navigate('/error')
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return !loading ? (
    <CenteredContentLayout>
      <Container>
        <Header icon="back" onBack={onBack}>
          Welcome {prettyNamePrint(firstName)}
        </Header>
        <Content>
          <Stack align="center" space="large">
            <Box height={154} width={200}>
              <Animation data={tickAnimation} />
            </Box>
            <Text color="higherEmphasis" prefab="body">
              {sessionContext.serviceNames &&
                `You are now a proud new member of ${sessionContext.serviceNames[0]}. `}
              Please use the HUB at your centre for all future sign in and out.
            </Text>
            <TextField label="Email" name="email" value={sessionContext.identifier || ''} readOnly disabled />
            <Text color="higherEmphasis" prefab="subtitle">
              Confused?{' '}
              <Anchor
                color="primary"
                prefab="subtitle"
                href="https://support.ourxplor.com/hc/en-us/articles/360025854112-HUB-Parent-Sign-In-">
                Learn what Hub Guests
              </Anchor>{' '}
              can do.
            </Text>
          </Stack>
        </Content>
      </Container>
    </CenteredContentLayout>
  ) : (
    <></>
  )
}

export default HubGuestLanding

// Based off playground/src/ui-components/Card.tsx
import React from 'react'

import styled from 'styled-components'
import {
  BorderRadiusProps,
  BoxShadowProps,
  ZIndexProps,
  borderRadius,
  boxShadow,
  zIndex,
} from 'styled-system'

import Box, { BoxProps } from './Box'

type Props = BoxProps & BorderRadiusProps & BoxShadowProps & ZIndexProps

const Card = styled(Box)<Props>`
  ${borderRadius};
  ${boxShadow};
  ${zIndex};
`

Card.displayName = 'Card'

Card.defaultProps = {
  bg: 'surfacePrimary',
  borderRadius: '1',
  boxShadow: '1dp',
  zIndex: 0,
}

export default React.memo(Card)

import React, { useState } from 'react'
import useFetch from 'use-http'
import download from 'downloadjs'

import { Box, Button, Center, Columns, Column, Heading, Stack } from '@myxplor/stardust'

import MessageLogCard from './MessageLogCard'

interface Props {
  query: string
  messageLogs?: Stargate.MessageLog[]
}

const QueryQKMigrationsResults = ({ query, messageLogs }: Props) => {
  const [displayProcessed, setDisplayProcessed] = useState(true)
  const { request, response } = useFetch()

  const onDownloadReport = async () => {
    await request.get(`/api/v1/admin/${query}/message-logs/report`)

    if (response && response.ok) {
      response.blob().then(csvData => {
        download(csvData, 'error_report.csv', 'text/csv')
      })
    }
  }

  const onToggleProcessed = () => {
    setDisplayProcessed(!displayProcessed)
  }

  return messageLogs && messageLogs.length > 0 ? (
    <Stack space="small">
      <Columns>
        <Column align="center">
          <Button onClick={onToggleProcessed}>
            {displayProcessed ? 'Hide Processed' : 'Show Processed'}
          </Button>
        </Column>
        <Column align="center">
          <Button onClick={onDownloadReport}>Download Report</Button>
        </Column>
      </Columns>
      {messageLogs
        .filter(messageLog => displayProcessed || messageLog.status != 'processed')
        .map(messageLog => (
          <MessageLogCard key={messageLog.id} messageLog={messageLog} />
        ))}
    </Stack>
  ) : (
    <Box padding="large" width={1}>
      <Center>{messageLogs ? <Heading level={1}>{'🤷‍♀️'}</Heading> : null}</Center>
    </Box>
  )
}

export default QueryQKMigrationsResults

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import React from 'react'
import ReactDOM from 'react-dom'

import { DarkTheme, LightTheme, StardustProvider } from '@myxplor/stardust'

import * as Analytics from '~/analytics'

import { AuthProvider } from '~/contexts/AuthContext'
import { SessionProvider } from '~/contexts/SessionContext'
import config from '~/config'
import App from '~/pages/App'

const themes = {
  dark: DarkTheme,
  light: LightTheme,
}

Analytics.initialise()
Analytics.initializeGoogleTagManager()

// Deal with New Relic being dumb
const nr = (window as any).newrelic
nr.addRelease('stagate-client', config.VERSION)

const Root = () => (
  <StardustProvider activeTheme="light" themes={themes}>
    <AuthProvider>
      <SessionProvider>
        <App />
      </SessionProvider>
    </AuthProvider>
  </StardustProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

import React from 'react'

const Rooms = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 14.375C7.63071 14.375 8.75 13.2557 8.75 11.875C8.75 10.4943 7.63071 9.375 6.25 9.375C4.86929 9.375 3.75 10.4943 3.75 11.875C3.75 13.2557 4.86929 14.375 6.25 14.375Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 19.375V18.75C9.375 17.0241 7.97589 15.625 6.25 15.625C4.52411 15.625 3.125 17.0241 3.125 18.75V19.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 14.375C15.1307 14.375 16.25 13.2557 16.25 11.875C16.25 10.4943 15.1307 9.375 13.75 9.375C12.3693 9.375 11.25 10.4943 11.25 11.875C11.25 13.2557 12.3693 14.375 13.75 14.375Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 19.375V18.75C16.8745 17.4866 16.1134 16.3478 14.9462 15.8641C13.7791 15.3805 12.4355 15.6472 11.5416 16.54"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.375 19.375V10L10 0.625L0.625 10V19.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Rooms

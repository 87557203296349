import React, { ReactNode } from 'react'

import { Box } from '@myxplor/stardust'

interface Props {
  children: ReactNode
}

const CenteredContentLayout = ({ children }: Props) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight="100vh"
      padding={['none', 'large']}
      width="100vw">
      <Box
        backgroundColor="foreground"
        borderRadius={['none', 'small']}
        boxShadow={['none', '1dp']}
        maxWidth={['100%', '480px']}
        width={1}>
        {children}
      </Box>
    </Box>
  )
}

export default CenteredContentLayout

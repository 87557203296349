import React, { useEffect, useContext, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Anchor, Box, Stack, Text } from '@myxplor/stardust'

import { AuthContext } from '~/contexts/AuthContext'
import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'
import EmailIcon from '~/icons/Email'

interface Props extends RouteComponentProps {
  resendEndpoint: string
}

const ResendEmail = ({ resendEndpoint }: Props) => {
  const { request, response } = useFetch()
  const { token } = useContext(AuthContext)
  const [sent, setSent] = useState(false)
  const onBack = () => navigate('/')

  const sendEmail = async () => {
    await request.post(resendEndpoint, { token })

    if (!response.ok) {
      navigate('/error')
    }

    setSent(true)
  }

  useEffect(() => {
    sendEmail()
  }, [])

  return sent ? (
    <Container>
      <Header icon="back" onBack={onBack}>
        Email Expired
      </Header>
      <Content>
        <Stack align="center" space="xxlarge">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Stack space="small">
            <Text color="highestEmphasis" prefab="body">
              The link you have followed has expired for security reasons. Please follow the instructions that
              have been sent to your inbox with a new link.
            </Text>
            <Text color="highestEmphasis" prefab="body">
              Can&apos;t find it? Check your junk folder or{' '}
              <Anchor prefab="body" onClick={sendEmail}>
                resend email.
              </Anchor>
            </Text>
          </Stack>
        </Stack>
      </Content>
      <Footer />
    </Container>
  ) : (
    <></>
  )
}

export default ResendEmail

import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import useFetch from 'use-http'

type Props = RouteComponentProps

import { Box, Card, Center, Form, Heading, Loading, Stack, Text, TextField } from '@myxplor/stardust'

const BulkUpgrade = (_props: Props) => {
  const { request, response, loading } = useFetch()
  const [query, setQuery] = useState('')

  const onFormSubmit = async () => {
    await request.post('/api/v1/admin/bulk-upgrade', { service_id: query })

    if (response.ok) {
      confirm('Upgrade emails sent')
    } else {
      confirm('Something went wrong...')
    }
  }

  return (
    <Center y={false}>
      <Box maxWidth={900} width={1}>
        <Stack space="small">
          {loading && <Loading size="large" />}
          {!loading && (
            <Card>
              <Box padding="large">
                <Stack space="small">
                  <Heading level={4}>Bulk Upgrade</Heading>

                  <Text color="highestEmphasis">Enter an Xplor Service ID to send upgrade emails</Text>

                  <Box paddingTop="small" width={1}>
                    <Form onSubmit={onFormSubmit}>
                      <TextField
                        autoComplete="off"
                        label="Xplor Service ID"
                        name="search"
                        onChange={(value: string) => setQuery(value)}
                      />
                    </Form>
                  </Box>
                </Stack>
              </Box>
            </Card>
          )}
        </Stack>
      </Box>
    </Center>
  )
}

export default BulkUpgrade

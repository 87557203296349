import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Anchor, Box, Image, Stack, Text } from '@myxplor/stardust'

import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'
import xplorRocketImage from '~/assets/images/xplor_rocket.png'
import CenteredContentLayout from '~/layouts/CenteredContentLayout'

type Props = RouteComponentProps

const DeprecatedFeature = (_props: Props) => {
  return (
    <CenteredContentLayout>
      <Container>
        <Header>Feature No Longer Supported</Header>
        <Content>
          <Stack align="center" space="xxlarge">
            <Box height={72}>
              <Image src={xplorRocketImage} />
            </Box>
            <Stack space="small">
              <Text color="highestEmphasis" prefab="body">
                The Xplor ID management tool has now been replaced by a system that automatically merges new
                profiles.
                <br />
                <br />
                If you are starting at a new centre, make sure the centre uses your existing login email when
                creating your profile and check your email inbox to approve the combined profiles.
                <br />
                <br />
                If you need additional help, you can{' '}
                <Anchor
                  color="primary"
                  prefab="body"
                  href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=myxplor.zendesk.com">
                  contact support
                </Anchor>
                .
              </Text>
            </Stack>
          </Stack>
        </Content>
        <Footer />
      </Container>
    </CenteredContentLayout>
  )
}

export default DeprecatedFeature

import React from 'react'

const VisibilityOn = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="24" height="15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7452 12.0965C24.0824 12.4706 24.0824 13.0294 23.7452 13.4036C22.6756 14.5741 21.2744 15.8348 19.614 16.9361C17.1545 18.5674 14.5917 19.5179 12 19.5179C9.40837 19.5179 6.8456 18.5674 4.38605 16.9361C2.72569 15.8348 1.32447 14.5741 0.252645 13.401C-0.0823148 13.0294 -0.0823148 12.4706 0.254921 12.0965C1.32447 10.9259 2.72569 9.6652 4.38605 8.56393C6.8456 6.93258 9.40837 5.98215 12 5.98215C14.5917 5.98215 17.1545 6.93258 19.614 8.56393C21.2744 9.6652 22.6756 10.9259 23.7452 12.0965ZM19.0613 9.3973C16.7522 7.86576 14.3696 6.98216 12 6.98216C9.63039 6.98216 7.24782 7.86576 4.93876 9.3973C3.3633 10.4423 2.02662 11.6428 1.01233 12.75C2.02859 13.859 3.36445 15.0585 4.93876 16.1027C7.24782 17.6343 9.63039 18.5179 12 18.5179C14.3696 18.5179 16.7522 17.6343 19.0613 16.1027C20.6367 15.0578 21.9734 13.8572 22.9877 12.75C21.9714 11.641 20.6356 10.4415 19.0613 9.3973ZM12.0001 16.625C9.79492 16.625 8.00007 14.8943 8.00007 12.75C8.00007 10.6058 9.79492 8.87501 12.0001 8.87501C14.2052 8.87501 16.0001 10.6058 16.0001 12.75C16.0001 14.8943 14.2052 16.625 12.0001 16.625ZM12 15.625C13.6609 15.625 15 14.3337 15 12.75C15 11.1664 13.6609 9.87502 12 9.87502C10.3392 9.87502 9.00005 11.1664 9.00005 12.75C9.00005 14.3337 10.3392 15.625 12 15.625Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill="currentColor" />
    </g>
  </svg>
)

export default React.memo(VisibilityOn)

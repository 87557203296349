import React, { useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Anchor, Box, Button, Form, Stack, Text, TextField } from '@myxplor/stardust'

import { getErrorMessage } from '~/api'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import { isEmailValid, isPhoneValid } from '../../utils'

import EnvelopeIcon from '~/icons/Envelope'

type Props = RouteComponentProps

const ResetPIN = (_props: Props) => {
  const [identifier, setIdentifier] = useState('')
  const { error, request, loading } = useFetch()
  const [showError, setShowError] = useState(true)
  const [emailError, setEmailError] = useState(false)
  const body = { user: identifier, reset_type: 'phone' }
  const errorMessage = getErrorMessage(error)
  const validEmail = isEmailValid(body.user)
  const validPhoneNumber = isPhoneValid(body.user)

  const onBack = () => history.back()

  const onForgotPhoneNumber = () => navigate('/account-recovery/forgot-phone-number')

  const onSubmit = () => {
    if (validEmail || validPhoneNumber) {
      setEmailError(false)
      request.post('/api/v1/send-password-reset', body).then(({ data }) => {
        if (!data) {
          setShowError(true)
        } else {
          navigate('/account-recovery/check-inbox', { state: { resetType: 'phone' } })
        }
      })
    } else {
      setEmailError(true)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        <Header icon="back" onBack={onBack}>
          Reset PIN
        </Header>

        <Content>
          <Stack align="center" space="large">
            <Text color="higherEmphasis" prefab="body">
              Enter the email address or mobile number associated with your account and we&apos;ll send you a
              reset link
            </Text>

            <TextField
              label="Mobile Number or Email"
              name="identifier"
              value={identifier}
              helpText={errorMessage && showError ? errorMessage : undefined}
              invalid={error && showError}
              trailingIcon={
                <Box height={24} width={24} color="primary">
                  <EnvelopeIcon />
                </Box>
              }
              onChange={(value: string) => {
                setIdentifier(value)
                setShowError(false)
              }}
            />

            {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}
            {emailError && <Alert tone="negative">Please check email or phone format.</Alert>}

            <Anchor onClick={onForgotPhoneNumber}>Forgot phone number?</Anchor>
          </Stack>
        </Content>

        <Footer>
          <Button type="submit" disabled={!identifier || loading} fill size="large">
            Send reset link
          </Button>
        </Footer>
      </Container>
    </Form>
  )
}

export default ResetPIN

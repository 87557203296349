import React from 'react'
import { Router, Redirect } from '@reach/router'

import AdminLogin from '~/pages/AdminLogin'
import Admin from '~/pages/Admin'
import SsoLogin from '~/pages/SsoLogin/'

// Routes for Authenticated admins
const RouterAdmin = () => {
  return (
    <Router>
      <AdminLogin path="admin/" />
      <AdminLogin path="admin/login/" />
      <Admin path="admin/ops/*" />
      <SsoLogin path="sso" />
      <Redirect from="*" to="/admin" default noThrow />
    </Router>
  )
}

export default RouterAdmin

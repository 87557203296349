import React, { useContext, useState } from 'react'
import { Router, RouteComponentProps, navigate } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import { generateCustomTokenOptions, getErrorMessage } from '~/api'
import SetPassword from '~/components/SetPassword'
import SetPin from '~/components/SetPin'
import SetPhone from '~/components/SetPhone'
import Success from './Success'

interface Props extends RouteComponentProps {
  finaliseEndpoint: string
}

const CreateAccountForm = (props: Props) => {
  const { token } = useContext(AuthContext)
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
  const { request, response } = useFetch({
    ...generateCustomTokenOptions(token),
    credentials: 'include',
  })

  const finaliseUser = async (phone: string) => {
    setErrorMessage(null)

    await request.post(props.finaliseEndpoint, {
      phone: phone,
      sso_only: sessionDetails.ssoOnly,
      email: sessionDetails.email,
      password: sessionDetails.password,
      pin: sessionDetails.pin,
    })

    if (response.ok) {
      const data = response.data.data

      setSessionDetails({
        ...sessionDetails,
        referrer: data.strategy_type === 'Educator' ? 'playground' : 'home-web',
        firstName: data.first_name,
        serviceNames: data.service_names,
        identifier: data.email,
        strategyType: data.strategy_type,
        strategyID: data.strategy_id,
        redirect: data.redirect,
      })

      navigate(data.strategy_type === 'Xplorer' ? '/hubguest' : 'success')
    } else {
      setErrorMessage(getErrorMessage(response))
    }
  }

  const onBack = () => history.back()

  const onPasswordClear = () => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, password: null })
  }

  const onPasswordNext = (password: string) => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, password })
    navigate('set-pin')
  }

  const onPinClear = () => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, pin: null })
  }

  const onPinNext = (pin: string) => {
    setErrorMessage(null)
    setSessionDetails({ ...sessionDetails, pin })
    navigate('set-phone')
  }

  return (
    <Router>
      <SetPassword
        errorMessage={errorMessage}
        onClear={onPasswordClear}
        onBack={onBack}
        onNext={onPasswordNext}
        path="set-password"
      />
      <SetPin onClear={onPinClear} onBack={onBack} onNext={onPinNext} path="set-pin" />
      <SetPhone errorMessage={errorMessage} onBack={onBack} onNext={finaliseUser} path="set-phone" />
      <Success path="success" />
    </Router>
  )
}

export default CreateAccountForm

import React, { ReactNode, useState } from 'react'

interface ContextProps {
  token: Nullable<Stargate.AuthToken>
  isAdmin: boolean
  setAuthContext(token: Nullable<Stargate.AuthToken>, isAdmin?: boolean): void
}

export const AuthContext = React.createContext<ContextProps>({
  token: null,
  isAdmin: false,
  setAuthContext: () => null,
})

interface Props {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<Nullable<Stargate.AuthToken>>(null)
  const [isAdmin, setAdmin] = useState(false)

  const setAuthContext = (token: Nullable<Stargate.AuthToken>, admin = false) => {
    setAdmin(admin)
    setToken(token)
  }

  return <AuthContext.Provider value={{ token, isAdmin, setAuthContext }}>{children}</AuthContext.Provider>
}

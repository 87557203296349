import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import RPath from 'ramda/src/path'

import useFetch from 'use-http'

import QueryQKMigrationsResults from './QueryQKMigrationsResults'

type Props = RouteComponentProps

import { Alert, Box, Card, Center, Form, Heading, Loading, Stack, Text, TextField } from '@myxplor/stardust'

const QueryQKMigrations = (_props: Props) => {
  const { request, data, loading, error } = useFetch()
  const [query, setQuery] = useState('')

  const messageLogs: Stargate.MessageLog[] | undefined = RPath(['data', 'message_logs'], data)

  const fetchData = async () => {
    request.get(`/api/v1/admin/message-logs?service_id=${query}`)
  }

  const onFormSubmit = async () => fetchData()

  return (
    <Center y={false}>
      <Box maxWidth={900} width={1}>
        <Stack space="small">
          <Card>
            <Box padding="large">
              <Stack space="small">
                <Heading level={4}>Query QK Migrations</Heading>

                <Text color="highestEmphasis">Enter an Xplor Service ID to search for QK migrations:</Text>

                <Box paddingTop="small" width={1}>
                  <Form onSubmit={onFormSubmit}>
                    <TextField
                      autoComplete="off"
                      label="Xplor Service ID"
                      name="search"
                      onChange={(value: string) => setQuery(value)}
                    />
                  </Form>
                </Box>
              </Stack>
            </Box>
          </Card>

          {loading ? (
            <Loading size="large" />
          ) : error ? (
            <Alert tone="negative">{error}</Alert>
          ) : (
            <QueryQKMigrationsResults query={query} messageLogs={messageLogs} />
          )}
        </Stack>
      </Box>
    </Center>
  )
}

export default QueryQKMigrations

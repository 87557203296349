import React, { useState } from 'react'

import hoverMixin from '~/components/atoms/hoverMixin'

import Flex from '~/components/atoms/Flex'
import Box from '~/components/atoms/Box'
import Text from '~/components/atoms/Text'
import Interactive from '~/components/atoms/Interactive'
import Heading from '~/components/atoms/Heading'

import ErrorMessage from '~/components/molecules/ErrorMessage'

import Avatar from '~/components/organisms/Avatar'

import styled from 'styled-components'

import { StardorStrategy, authWithStrategy } from './Strategy'

interface UserStrategySelectorProps {
  userData: StardorStrategy[] | null | undefined
  appReferrer: any
}

const InteractiveHover = styled(Interactive)`
  ${hoverMixin};
  background-color: ${(props: any) => (props.selected ? props.theme.colors.primaryLight : 'initial')};
`

// TODO: In future look at supporting SSO overlay for non-accessible strategies.
// const ProfileSSOOverlay = styled(Box)`
//   position: fixed;
//   display: block;
//   width: 500px;
//   height: 60px;
//   z-index: 2;
//   background-color: rgba(0, 0, 0, 0.2);
//   backdrop-filter: blur(0.8px);
// `
// const SSONotice = styled(Text.span)`
//   display: block;
//   font-size: 0.75rem;
//   font-weight: bold;
//   margin-left: 238px;
//   margin-top: 24px;
// `

const isXplorStrategy = (strategy: StardorStrategy) => {
  return strategy.id > 0
}

const filterEducatorStrategies = (strategies: StardorStrategy[]) => {
  return strategies.filter(strategy => strategy.strategy === 'Educator')
}

const filterGuardianStrategies = (strategies: StardorStrategy[]) => {
  return strategies.filter(strategy => strategy.strategy === 'Parent' || strategy.strategy === 'Guardian')
}

const filterAdminStrategies = (strategies: StardorStrategy[]): StardorStrategy[] => {
  return strategies.filter(strategy => strategy.strategy.includes('Admin'))
}

const getServiceNames = (strategy: StardorStrategy) => {
  if (strategy.services && strategy.services.length > 0) {
    return strategy.services.join(', ')
  } else {
    return ''
  }
}

const getDisplayName = (strategy: StardorStrategy) => {
  return isXplorStrategy(strategy) ? strategy.display_name : getServiceNames(strategy)
}

const getAvatarInitials = (strategy: StardorStrategy) => {
  const names = getDisplayName(strategy).split(' ')
  return names
    .map(name => name[0])
    .join('')
    .replaceAll(/[^A-Za-z0-9]/g, '')
}

const UserStrategySelector = (props: UserStrategySelectorProps) => {
  const { userData } = props
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [strategies, setStrategies] = useState(userData || [])
  // Valid if strategies are present from the incoming data
  const [valid, setValidity] = useState(strategies && strategies.length > 0)
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const onStrategySelect = async (strategy: StardorStrategy) => {
    try {
      const response = await authWithStrategy(strategy)
      if (response?.ok) {
        response.body
          ?.getReader()
          .read()
          .then(result => {
            const body = JSON.parse(new TextDecoder().decode(result.value))
            window.location.assign(body.data.url)
          })
      } else {
        setValidity(false)
      }
    } catch (e) {
      console.error(e)
      window.location.replace('/')
    }
  }

  const parentStrategies = filterGuardianStrategies(strategies)
  const educatorStrategies = filterEducatorStrategies(strategies)
  const adminStrategies = filterAdminStrategies(strategies)

  const renderStrategies = (name: string, userStrategies: StardorStrategy[]) => {
    if (userStrategies.length === 0) return null
    return (
      <Box pb={3}>
        <Text.span color="black" fontSize={0} letterSpacing="3.2px" px={3} upper>
          {name} Profiles
        </Text.span>
        <Box pt={1} key={`${name}`}>
          {userStrategies.map(strategy => (
            <InteractiveHover
              key={`${strategy.strategy}/${strategy.id}`}
              data-test={`${name.toLowerCase()}-profile`}
              onClick={() => onStrategySelect(strategy)}>
              <Flex alignItems="center" px={3} py={2}>
                <Avatar xsmall context={name} src={null} text={getAvatarInitials(strategy)} />
                <Box pl={3}>
                  <Text lineHeight={1.4}>{getDisplayName(strategy)}</Text>
                </Box>
              </Flex>
            </InteractiveHover>
          ))}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {!valid && <ErrorMessage data-test="error">No Xplor profile found / Invalid credentials</ErrorMessage>}
      <Heading.h4 lineHeight={1} mb={0} mt={0} p={3} pb={3}>
        Choose profile:
      </Heading.h4>
      {
        <Box maxHeight="400px" overflowY="auto">
          <>
            {renderStrategies('Admin', adminStrategies)}
            {renderStrategies('Educator', educatorStrategies)}
            {renderStrategies('Parent', parentStrategies)}
          </>
        </Box>
      }
    </>
  )
}

UserStrategySelector.displayName = 'UserStrategySelector'

export default React.memo(UserStrategySelector)

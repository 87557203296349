import React, { useContext, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { SessionContext } from '~/contexts/SessionContext'
import { generateCustomTokenOptions } from '~/api'

type Props = RouteComponentProps

const ManualUpgradeVerify = (_props: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const [sessionDetails, setSessionDetails] = useContext(SessionContext)
  const token = new URLSearchParams(window.location.search).get('token') as string
  const { request, response } = useFetch({
    ...generateCustomTokenOptions(token, 'upgrade'),
    credentials: 'include',
  })

  const verify = async () => {
    await request.post('/api/v1/upgrades/manual/finalise', {})

    if (response.ok) {
      const data = response.data.data

      if (data.upgrade_status === 'completed') {
        setSessionDetails({
          ...sessionDetails,
          referrer: data.strategy_type === 'Educator' ? 'playground' : 'home-web',
          firstName: data.first_name,
          strategyType: data.strategy_type,
          strategyID: data.strategy_id,
          identifier: data.upgrade_email_address,
        })

        navigate(data.strategy_type === 'Xplorer' ? '/hubguest' : '/manual-upgrade/success')
        setAuthContext(token)
      } else if (data.upgrade_status === 'sent') {
        navigate('/manual-upgrade/resent-email')
        setSessionDetails({ ...sessionDetails, identifier: data.upgrade_email_address })
        setAuthContext(token)
      } else {
        navigate('/error')
      }
    } else {
      navigate('/error')
    }
  }

  useEffect(() => {
    if (token) {
      verify()
    } else {
      navigate('/error')
    }
  }, [])

  return <></>
}

export default ManualUpgradeVerify

import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Box from '~/components/atoms/Box'
import Card from '~/components/atoms/Card'
import Flex from '~/components/atoms/Flex'
import Image from '~/components/atoms/Image'

import UserStrategySelector from './UserStrategySelector'
import homeIcon from '~/assets/images/home_profile_selection.svg'
import playgroundIcon from '~/assets/images/playground_profile_selection.png'
import Theme from '~/pages/Theme'

type Props = RouteComponentProps

const ProfileSelector = (_props: Props) => {
  const appReferrer = new URLSearchParams(window.location.search).get('app_referrer') as Nullable<
    Stargate.Referrer
  >
  const encodedUserData = new URLSearchParams(window.location.search).get('user_data') as Nullable<string>
  let parsedUserData
  if (!encodedUserData) {
    window.location.replace('/')
  } else {
    const decodedUserData = Buffer.from(encodedUserData, 'base64')
    console.log(`encodedUserData from URL: ${encodedUserData}`)
    console.log(`decodedUserData: ${decodedUserData}`)
    parsedUserData = JSON.parse(decodedUserData.toString())
  }

  return (
    <Theme>
      <Box display="flex" height="100vh" width="100vw">
        <Flex alignItems="center" flexDirection="column" height="100vh" justifyContent="center" width="100vw">
          <Card width="500px">
            <Flex justifyContent="center" width={1}>
              <Box width="80px" pb="3" pt="4">
                <Image src={appReferrer === 'home-web' ? homeIcon : playgroundIcon} />
              </Box>
            </Flex>
            <UserStrategySelector userData={parsedUserData} appReferrer={appReferrer} />
          </Card>
        </Flex>
      </Box>
    </Theme>
  )
}

ProfileSelector.displayName = 'ProfileSelector'
export default ProfileSelector

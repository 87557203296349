import React, { useContext, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { generateCustomTokenOptions } from '~/api'

type Props = RouteComponentProps

const Status = (_props: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const token = new URLSearchParams(window.location.search).get('token') as string
  const { request, response } = useFetch({ ...generateCustomTokenOptions(token), credentials: 'include' })

  const status = async () => {
    await request.get('/api/v1/service-login-onboarding/status')

    if (response.ok) {
      navigate('/service-login-onboarding/set-password')
      setAuthContext(token)
    } else if (response.status == 401) {
      navigate('/service-login-onboarding/expired')
    } else {
      navigate('/error')
    }
  }

  useEffect(() => {
    if (token) {
      status()
    } else {
      navigate('/error')
    }
  }, [])

  return <></>
}

export default Status

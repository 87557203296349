import React from 'react'

import { Box, Center, Heading, Stack } from '@myxplor/stardust'

import XplorIDCard from './XplorIDCard'

interface Props {
  xplorIDs?: Stargate.XplorID[]
  onSendPINResetEmail(identifier: string): void
  onSendPasswordResetEmail(identifier: string): void
  onUnlinkAccount(xplorID: number, account: Stargate.LinkedAccount): void
  onUpdatePhoneNumber(email: string, phoneNumber: string): void
  onSendMagicLink(xplorID: number, strategyType: string, strategyID: string): void
}

const QueryXplorIDsResult = ({
  xplorIDs,
  onSendPINResetEmail,
  onSendPasswordResetEmail,
  onUnlinkAccount,
  onUpdatePhoneNumber,
  onSendMagicLink,
}: Props) => {
  return xplorIDs && xplorIDs.length > 0 ? (
    <Stack space="small">
      {xplorIDs.map(user => (
        <XplorIDCard
          key={user.xplor_id}
          user={user}
          onSendPINResetEmail={onSendPINResetEmail}
          onSendPasswordResetEmail={onSendPasswordResetEmail}
          onUnlinkAccount={onUnlinkAccount}
          onUpdatePhoneNumber={onUpdatePhoneNumber}
          onSendMagicLink={onSendMagicLink}
        />
      ))}
    </Stack>
  ) : (
    <Box padding="large" width={1}>
      <Center>{xplorIDs ? <Heading level={1}>{'🤷‍♀️'}</Heading> : null}</Center>
    </Box>
  )
}

QueryXplorIDsResult.displayName = 'QueryXplorIDsResult'
export default QueryXplorIDsResult

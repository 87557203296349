import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Box, Stack, Text } from '@myxplor/stardust'
import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'

import EmailIcon from '~/icons/Email'

type Props = RouteComponentProps

const ExpiredMergeXplorIdsEmail = (_props: Props) => {
  const onBack = () => {
    history.replaceState(null, '', '/')
    navigate('/log-in')
  }

  return (
    <Container>
      <Header icon="close" onBack={onBack}>
        Merge Accounts Email Expired
      </Header>

      <Content>
        <Stack align="center" space="xxlarge">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Stack space="small">
            <Text color="highestEmphasis" prefab="body">
              This merge accounts email has expired. Please contact support.
            </Text>
          </Stack>
        </Stack>
      </Content>
      <Footer />
    </Container>
  )
}

export default ExpiredMergeXplorIdsEmail

import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Anchor, Stack, Text } from '@myxplor/stardust'
import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'
import CenteredContentLayout from '~/layouts/CenteredContentLayout'
import Logo from '~/icons/Logo'

type Props = RouteComponentProps

const Error = (_props: Props) => {
  const onBack = () => {
    navigate('/')
  }

  return (
    <CenteredContentLayout>
      <Container>
        <Header icon="close" iconPadding="xxlarge" onBack={onBack} overline="XPLOR">
          Error
        </Header>
        <Content>
          <Stack align="center" space="xxxlarge">
            <Logo />
            <Text color="higherEmphasis" prefab="body">
              We have detected a problem with your account and are unable to process your request. Please try
              again, or{' '}
              <Anchor
                color="primary"
                prefab="body"
                href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=myxplor.zendesk.com">
                contact support
              </Anchor>
              .
            </Text>
          </Stack>
        </Content>
      </Container>
    </CenteredContentLayout>
  )
}

export default Error

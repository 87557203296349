import { navigate } from '@reach/router'

import config from '~/config'

const HOME_STRATEGIES = ['Parent', 'QK/Guardian', 'Discover/Guardian']

const PLAYGROUND_STRATEGIES = [
  'Discover/Educator',
  'Educator',
  'GroupAdmin',
  'ProviderAdmin',
  'QK/Educator',
  'ServiceAdmin',
  'SuperAdmin',
]

const hasStrategy = (strategies: string[], userContexts: Stargate.UserContext[]) => {
  return !!userContexts.find(uc => strategies.includes(uc.strategy_type))
}

const redirectToHome = () => window.location.assign(`${config.HOMEWEB_URL}`)
const redirectToPlayground = () => window.location.assign(`${config.PLAYGROUND_URL}`)

const navigateUsingUserContexts = async (authToken: string, referrer: Nullable<Stargate.Referrer>) => {
  const headers = { Authorization: `Bearer ${authToken}` }
  const userContextsRequest = await fetch(`${config.STARGATE_API}/api/v1/contexts`, { headers })
  const userContextsJson = await userContextsRequest.json()
  const userContexts = userContextsJson.data.contexts as Stargate.UserContext[]

  const hasHomeStrategy = hasStrategy(HOME_STRATEGIES, userContexts)
  const hasPlaygroundStrategy = hasStrategy(PLAYGROUND_STRATEGIES, userContexts)

  /**
   * TODO: If we are wanting to switch all profile selection to use stargate-client,
   * we'll be needing to navigate directly to the profile selection route below.
   */
  if (hasHomeStrategy && (referrer === 'home-web' || !hasPlaygroundStrategy)) {
    // TODO: navigate to context selector with Home theme, read above.
    redirectToHome()
  } else if (hasPlaygroundStrategy && (referrer === 'playground' || !hasHomeStrategy)) {
    // TODO: navigate to context selector with Playground theme, read above.
    redirectToPlayground()
  } else {
    navigate('/product-selector')
  }
}

export default (
  { linked, strategyType }: Session,
  authToken?: Nullable<string>,
  referrer?: Nullable<Stargate.Referrer>
) => {
  const hasUserContexts = linked && authToken
  const isHubGuest = strategyType === 'Xplorer'
  const isPlaygroundStrategy = strategyType && PLAYGROUND_STRATEGIES.includes(strategyType)

  if (isHubGuest) {
    navigate('/hubguest')
  } else if (hasUserContexts) {
    navigateUsingUserContexts(authToken, referrer)
  } else if (isPlaygroundStrategy) {
    // TODO: navigate to context selector with Playground theme, read above.
    redirectToPlayground()
  } else {
    // TODO: navigate to context selector with Home theme, read above.
    redirectToHome()
  }
}

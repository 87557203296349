import tokens from '../tokens'

export default {
  colors: {
    negative: tokens.colors.superGiantRed6,
    negativeLight: tokens.colors.superGiantRed0,
    positive: tokens.colors.gaiaGreen2,

    surfacePrimary: tokens.colors.cosmicShade0,
    surfacePrimaryBorder: tokens.colors.cosmicShade4,
    surfaceSecondary: tokens.colors.cosmicShade3,

    textPrimary: tokens.colors.cosmicShade9,
    textPrimaryLow: tokens.colors.cosmicShade7,
    textPrimaryMedium: tokens.colors.cosmicShade8,

    loadingBackground: tokens.colors.cosmicShade0,

    // Content aware

    educator: tokens.colors.nebulaBlue5,
    educatorDark: tokens.colors.nebulaBlue9,
    educatorLight: tokens.colors.nebulaBlue0,

    fileEmbedBtnColor: tokens.colors.cosmicShade6,
    fileEmbedTxtColor: tokens.colors.cosmicShade,

    parent: tokens.colors.hullOrange6,
    parentDark: tokens.colors.hullOrange9,
    parentLight: tokens.colors.hullOrange0,

    mentionedText: tokens.colors.nebulaBlue0,

    folder: tokens.colors.solYellow3,
    moment: tokens.colors.pulsarPurple5,
    momentLight: tokens.colors.pulsarPurple0,
    observation: tokens.colors.superGiantRed6,
    observationLight: tokens.colors.superGiantRed0,
    plan: tokens.colors.hullOrange6,
    planLight: tokens.colors.hullOrange0,
    story: tokens.colors.nebulaBlue5,
    storyLight: tokens.colors.nebulaBlue0,
    table: tokens.colors.hullOrange6,
    tableLight: tokens.colors.hullOrange0,
  },
}

const palette = {
  gaiaGreen0: '#DEF2E9',
  gaiaGreen1: '#AFDEC9',
  gaiaGreen2: '#79C8A6',
  gaiaGreen3: '#35B384',
  gaiaGreen4: '#00A46D',
  gaiaGreen5: '#009455',
  gaiaGreen6: '#00864B',
  gaiaGreen7: '#00753F',
  gaiaGreen8: '#006533',
  gaiaGreen9: '#00461E',
}

export default {
  ...palette,
  gaiaGreen: palette.gaiaGreen2,
}

import React from 'react'

const Mobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <mask id="a" width="12" height="24" x="6" y="0" maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16 .01L8 0C6.9 0 6 .9 6 2v16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V2c0-1.1-.9-1.99-2-1.99zM6.99998 22h2v2h-2v-2zM11 22h2v2h-2v-2zm4 0v2h2v-2h-2zm-6.99998-4H16v-1H8.00002v1zM16 15H8.00002V5H16v10zM8.00002 3.00001H16v-1H8.00002v1z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="#000" fillOpacity=".54" d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default React.memo(Mobile)

import React, { useContext, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { AuthContext } from '~/contexts/AuthContext'
import { generateCustomTokenOptions } from '~/api'

type Props = RouteComponentProps

const Ping = (_props: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const token = new URLSearchParams(window.location.search).get('token') as string
  const { request, response } = useFetch({ ...generateCustomTokenOptions(token), credentials: 'include' })

  const ping = async () => {
    await request.get('/api/v1/migrations/ping')

    if (response.ok) {
      navigate('/migration/create-account/set-password')
      setAuthContext(token)
    } else if (response.status === 401) {
      navigate('/migration/resend-email')
      setAuthContext(token)
    } else {
      navigate('/error')
    }
  }

  useEffect(() => {
    if (token) {
      ping()
    } else {
      navigate('/error')
    }
  }, [])

  return <></>
}

export default Ping

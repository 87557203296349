import React, { ReactNode, useContext } from 'react'
import { navigate, Link, Location } from '@reach/router'

import { Box, Button, Column, Columns, Heading, Inline, Stack, Text } from '@myxplor/stardust'

import { AuthContext } from '~/contexts/AuthContext'
import useFetch from 'use-http'
import config from '~/config'

interface NavigationItemProps {
  children: ReactNode
  location: string
  path: string
}

const NavigationItem = ({ children, location, path, ...props }: NavigationItemProps) => {
  const match = location.split('/').pop() === path.split('/').pop()
  return (
    <Box borderBottom="1px solid" borderColor="lowerEmphasis" width={1}>
      <Link to={path} {...props} style={{ textDecoration: 'none', width: '100%' }}>
        <Inline alignY="center" space="small" wrap={false}>
          <Box backgroundColor={match ? 'primary' : 'lowEmphasis'} height="48px" width="10px" />
          <Text color={match ? 'primary' : 'lowEmphasis'} prefab="button" weight={match ? 'bold' : 'regular'}>
            {children}
          </Text>
        </Inline>
      </Link>
    </Box>
  )
}

interface Props {
  children: ReactNode
}

const AdminLayout = ({ children }: Props) => {
  const { setAuthContext } = useContext(AuthContext)
  const { request } = useFetch(config.HODOR_API, { credentials: 'include' })

  const onLogout = async () => {
    await request.post('/api/v1/logout').then(_ => {
      setAuthContext(null)
      navigate('/sso')
    })
  }

  return (
    <Box height="100vh" width="100vw">
      <Columns>
        <Column align="center" width="220px">
          <Box boxShadow="1dp" height="100vh" width={1}>
            <Box padding="large">
              <Heading level={4}>Admin</Heading>
            </Box>

            <Box borderColor="lowerEmphasis" borderTop="1px solid">
              <Location>
                {({ location: { pathname } }) => {
                  return (
                    <Stack space="small">
                      <NavigationItem location={pathname} path="./xplor-id">
                        Query Xplor IDs
                      </NavigationItem>
                      <NavigationItem location={pathname} path="./qk-migrations">
                        Query QK Migrations
                      </NavigationItem>
                      <NavigationItem location={pathname} path="./merge-xplor-ids">
                        Merge Xplor IDs
                      </NavigationItem>
                      <NavigationItem location={pathname} path="./bulk-upgrade">
                        Bulk Upgrade
                      </NavigationItem>
                      <NavigationItem location={pathname} path="./impersonation">
                        Impersonation
                      </NavigationItem>
                    </Stack>
                  )
                }}
              </Location>
            </Box>

            <Box bottom={0} padding="large" position="absolute" width={1}>
              <Button fill size="large" onClick={onLogout}>
                Logout
              </Button>
            </Box>
          </Box>
        </Column>

        <Column width="fill">
          <Box padding="xxlarge" width={1}>
            {children}
          </Box>
        </Column>
      </Columns>
    </Box>
  )
}

export default AdminLayout

import React, { useContext } from 'react'
import { Provider as UseFetchProvider } from 'use-http'

import { generateOptions, baseEndpoint } from '~/api'
import { isNotNilOrEmpty } from '~/utils'
import { AuthContext } from '~/contexts/AuthContext'
import RouterAdmin from './RouterAdmin'
import RouterAuthenticated from './RouterAuthenticated'
import RouterUnauthenticated from './RouterUnauthenticated'

const XplorUniverse = () => {
  const { token, isAdmin } = useContext(AuthContext)
  const isAuthenticated = isNotNilOrEmpty(token)

  const router = () => {
    if (isAdmin) {
      return <RouterAdmin />
    } else if (isAuthenticated) {
      return <RouterAuthenticated />
    } else {
      return <RouterUnauthenticated />
    }
  }

  return (
    <UseFetchProvider url={baseEndpoint} options={generateOptions(token)}>
      {router()}
    </UseFetchProvider>
  )
}

XplorUniverse.displayName = 'XplorUniverse'
export default XplorUniverse

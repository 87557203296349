import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Animation, Box, Button, Stack, Text } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

type Props = RouteComponentProps

const AllDone = ({ location }: Props) => {
  const locationState = location && location.state ? (location.state as any) : null
  const resetType = locationState ? locationState.resetType : ''

  const onBack = () => history.back()
  const onDone = () => navigate('/')

  const confirmationText = () => {
    if (resetType == 'phone') {
      return 'Your PIN has been set. You can now login to Xplor Home and Hub with these credentials'
    } else {
      return 'Your new password has been set. You can now login to Xplor products with these credentials.'
    }
  }

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        All Done.
      </Header>

      <Content>
        <Stack align="center" space="small">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>

          <Text color="higherEmphasis" prefab="body">
            {confirmationText()}
          </Text>
        </Stack>
      </Content>

      <Footer>
        <Button fill size="large" onClick={onDone}>
          Confirm
        </Button>
      </Footer>
    </Container>
  )
}

export default AllDone

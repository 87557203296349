import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Anchor, Box, Stack, Text } from '@myxplor/stardust'

import { Container, Header, Content, Footer } from '~/layouts/ScrollableContentLayout'

import EmailIcon from '~/icons/Email'

type Props = RouteComponentProps

const CheckInbox = ({ location }: Props) => {
  const locationState = location && location.state ? (location.state as any) : null
  const resetType = locationState ? locationState.resetType : ''

  const onBack = () => history.back()

  const confirmationText = () => {
    if (resetType == 'phone') {
      return 'Email sent. Check your inbox and press on the link in the email to reset your pin.'
    } else {
      return 'Email sent. Check your inbox and press on the link in the email to reset your password.'
    }
  }

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Check your Inbox
      </Header>
      <Content>
        <Stack align="center" space="xxlarge">
          <Box height={73} width={96}>
            <EmailIcon />
          </Box>
          <Stack space="small">
            <Text color="highestEmphasis" prefab="body">
              {confirmationText()}
            </Text>
            <Text color="highestEmphasis" prefab="body">
              Can&apos;t find it? Check your junk folder or&nbsp;
              <Anchor prefab="body" onClick={onBack}>
                resend email.
              </Anchor>
            </Text>
          </Stack>
        </Stack>
      </Content>
      <Footer />
    </Container>
  )
}

export default CheckInbox

const palette = {
  nebulaBlue0: '#DFF2F1',
  nebulaBlue1: '#AFDEDC',
  nebulaBlue2: '#7BCAC5',
  nebulaBlue3: '#42B4AD',
  nebulaBlue4: '#00A49B',
  nebulaBlue5: '#01A39D',
  nebulaBlue6: '#008480',
  nebulaBlue7: '#00776B',
  nebulaBlue8: '#00675C',
  nebulaBlue9: '#004B40',
}

export default {
  ...palette,
  nebulaBlue: palette.nebulaBlue5,
}

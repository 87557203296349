import React, { useState, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Form, Button, Stack, Text, TextField } from '@myxplor/stardust'

import { getErrorMessage } from '~/api'

import { Container, Header, Footer, Content } from '~/layouts/ScrollableContentLayout'

import PasswordStrengthMeter from '~/components/PasswordStrengthMeter'

type Props = RouteComponentProps

const CreatePassword = (_props: Props) => {
  const [password, setPassword] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [repeatPassword, setRepeatPassword] = useState('')
  const [showError, setShowError] = useState(true)
  const { loading, error, request, response } = useFetch()
  const minPasswordLength = 8
  const resetToken = new URLSearchParams(window.location.search).get('token')
  const body = { reset_token: resetToken, new_password: password, reset_type: 'email' }
  const errorMessage = getErrorMessage(error)

  const onBack = () => history.back()

  const onBackToStart = () => {
    navigate('/')
  }

  const onSubmit = () => {
    if (password !== repeatPassword) {
      setPasswordsMatch(false)
    } else {
      // explicit history modification to redirect to '/' when browser back button pressed
      history.replaceState(null, '', '/')
      request.post('/api/v1/set-new-password', body).then(({ data }) => {
        if (!data) {
          setShowError(true)
        } else {
          navigate('/account-recovery/all-done', { state: { resetType: 'email' } })
        }
      })
    }
  }

  const checkToken = async () => {
    await request.post('/api/v1/password-reset', { reset_token: resetToken })

    if (response.status === 401) {
      navigate('/error')
    }
  }

  useEffect(() => {
    if (resetToken) {
      checkToken()
    } else {
      navigate('/error')
    }
  }, [])

  return (
    <Form onSubmit={error ? onBackToStart : onSubmit}>
      <Container>
        <Header icon="back" onBack={onBack}>
          Create Password
        </Header>

        <Content>
          <Stack space="small">
            {errorMessage && showError && <Alert tone="negative">{errorMessage}</Alert>}
            {!passwordsMatch && showError && <Alert tone="negative">Password does not match</Alert>}

            <TextField
              minLength={minPasswordLength}
              label="Password"
              name="xplor_password"
              type="password"
              value={password}
              onChange={(value: string) => {
                setShowError(false)
                setPasswordsMatch(true)
                setPassword(value)
              }}
            />
            <TextField
              minLength={minPasswordLength}
              label="Repeat Password"
              name="repeat_xplor_password"
              type="password"
              value={repeatPassword}
              onChange={(value: string) => {
                setShowError(false)
                setPasswordsMatch(true)
                setRepeatPassword(value)
              }}
            />

            <PasswordStrengthMeter password={password} />

            <Text decorate={password.length >= minPasswordLength ? 'thru' : 'none'}>
              {minPasswordLength} characters
            </Text>
          </Stack>
        </Content>

        <Footer>
          <Button type="submit" disabled={!password || !repeatPassword || loading} fill size="large">
            {error ? 'Try again' : 'Reset'}
          </Button>
        </Footer>
      </Container>
    </Form>
  )
}

export default CreatePassword

import React from 'react'

const Close = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 10.4L21.6 9L16 14.6L10.4 9L9 10.4L14.6 16L9 21.6L10.4 23L16 17.4L21.6 23L23 21.6L17.4 16L23 10.4Z"
      fill="currentColor"
    />
  </svg>
)

export default React.memo(Close)

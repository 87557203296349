const palette = {
  pulsarPurple0: '#F1E6FD',
  pulsarPurple1: '#DAC2FB',
  pulsarPurple2: '#C199F9',
  pulsarPurple3: '#A76CF8',
  pulsarPurple4: '#9146F6',
  pulsarPurple5: '#7A11F2',
  pulsarPurple6: '#6E08EC',
  pulsarPurple7: '#5D00E3',
  pulsarPurple8: '#4A00DE',
  pulsarPurple9: '#2000D9',
}

export default {
  ...palette,
  pulsarPurple: palette.pulsarPurple5,
}

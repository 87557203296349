import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Animation, Box, Stack, Text } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'

import { Container, Header, Content } from '~/layouts/ScrollableContentLayout'

type Props = RouteComponentProps

const Success = (_props: Props) => {
  const onBack = () => {
    history.replaceState(null, '', '/')
    navigate('/')
  }

  return (
    <Container>
      <Header icon="back" onBack={onBack}>
        Thanks!
      </Header>
      <Content>
        <Stack align="center" space="large">
          <Box height={154} width={200}>
            <Animation data={tickAnimation} />
          </Box>
          <Text color="higherEmphasis" prefab="body">
            Your account has been successfully linked.
          </Text>
        </Stack>
      </Content>
    </Container>
  )
}

export default Success
